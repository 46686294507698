import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ActivatedRoute, Router, RouterModule } from '@angular/router'

import { ButtonModule } from 'primeng/button'
import { CheckboxModule } from 'primeng/checkbox'
import { DialogModule } from 'primeng/dialog'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputTextModule } from 'primeng/inputtext'
import { TooltipModule } from 'primeng/tooltip'

import { MessageService } from 'primeng/api'
import { Subscription } from 'rxjs'

import { PricingPanelComponent } from '../pricing-panel/pricing-panel.component'
import { AuthService } from '../services/auth.service'
import { UserProfile, UserService } from '../services/user.service'
import { showErrorBox, showInfoBox, showSuccessBox } from '../utils'


@Component({
    selector: 'app-user-profile',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        InputNumberModule,
        InputTextModule,
        ButtonModule,
        CheckboxModule,
        TooltipModule,
        DialogModule,
        PricingPanelComponent,
    ],
    templateUrl: './user-profile.component.html',
    styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent implements OnInit, OnDestroy {
    public subs: Subscription = new Subscription()
    userProfile: UserProfile | null | undefined
    activeTab = 0

    fb: FormBuilder = new FormBuilder()
    userProfileForm = this.fb.group({
        undoLimit: [UserService.userProfileDefaults.undoLimit],
        zoomWithWheel: [UserService.userProfileDefaults.zoomWithWheel],
        newsletterConsent: [UserService.userProfileDefaults.newsletterConsent],
        termsConsent: [true],
    })

    accountDeleteLogoutDlgVisible: boolean = false
    deleteConfirmation: string = ''

    constructor(
        public msgSvc: MessageService,
        private userService: UserService,
        private route: ActivatedRoute,
        private auth: AuthService,
        private router: Router,
    ) {
        this.userProfileForm.controls.termsConsent.disable()
    }

    ngOnInit() {
        if (this.route.snapshot.url[0].path === 'user-plan') {
            this.activeTab = 1
        } else if (this.route.snapshot.url[0].path === 'user-delete') {
            this.activeTab = 2
        } else {
            this.activeTab = 0
        }

        this.route.url.subscribe({
            next: (urlSegments) => {
                if (urlSegments[0].path === 'user-plan') {
                    this.activeTab = 1
                } else if (urlSegments[0].path === 'user-delete') {
                    this.activeTab = 2
                }
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })

        this.userService.getUserProfile().subscribe({
            next: (userProfile: UserProfile | null | undefined) => {
                this.userProfile = userProfile
                if (userProfile) {
                    this.userProfileForm.patchValue(userProfile)
                }
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    saveUserProfile() {
        const v = this.userProfileForm.controls.newsletterConsent.value
        const patch: UserProfile = {
            undoLimit: this.userProfileForm.controls.undoLimit.value || UserService.userProfileDefaults.undoLimit,
            zoomWithWheel: typeof this.userProfileForm.controls.zoomWithWheel.value === 'boolean' ? this.userProfileForm.controls.zoomWithWheel.value : true,
            newsletterConsent: v !== undefined && v !== null ? v : UserService.userProfileDefaults.newsletterConsent
        }

        this.userService.updateUserProfile(patch).subscribe({
            next: (resp: any) => {
                showSuccessBox(this.msgSvc, $localize`Zapisywanie ustawień użytkownika`, $localize`Ustawienia zapisano`)
            },
            error: (err) => {
                console.error('error occured', err)
                showErrorBox(this.msgSvc, $localize`Zapisywanie ustawień użytkownika`, $localize`Wystąpił nieznany błąd`)
            }
        })
    }

    deleteUserAccount() {
        const obs = this.auth.deleteUserAccount()
        if (obs) {
            obs.subscribe({
                next: () => {
                    showSuccessBox(this.msgSvc, $localize`Kasowanie konta użytkownika`, $localize`Konto skasowano`)
                    this.auth.logout()
                    this.router.navigate(['/'])
                },
                error: (err) => {
                    if (err.code === 'auth/requires-recent-login') {
                        this.accountDeleteLogoutDlgVisible = true
                    } else {
                        console.error('error occured', err)
                        showErrorBox(this.msgSvc, $localize`Kasowanie konta użytkownika`, $localize`Wystąpił nieznany błąd`)
                    }
                }
            })
        }
    }

    okLogout() {
        showInfoBox(this.msgSvc, $localize`Kasowanie konta użytkownika`, $localize`Zostałeś wylogowany. Zaloguj się i ponownie zleć skasowanie konta.`)
        this.auth.logout()
        this.router.navigate(['/'])
    }
}
