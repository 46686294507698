import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Inject, LOCALE_ID, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { Router, RouterModule } from '@angular/router'

import { User } from '@angular/fire/auth'
import { ConfirmationService, MenuItem, MenuItemCommandEvent, MessageService } from 'primeng/api'
import { ButtonModule } from 'primeng/button'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DropdownModule } from 'primeng/dropdown'
import { InputTextModule } from 'primeng/inputtext'
import { MenuModule } from 'primeng/menu'
import { SkeletonModule } from 'primeng/skeleton'
import { TooltipModule } from 'primeng/tooltip'
import { catchError, concatMap, EMPTY, mergeMap, of, Subject, Subscription, throwError, timeout, zip } from 'rxjs'

import { AngularDeviceInformationService } from 'angular-device-information'
import { AutoSizeInputModule } from 'ngx-autosize-input'
import { DialogModule } from 'primeng/dialog'
import { DragDropModule } from 'primeng/dragdrop'
import { TableModule } from 'primeng/table'
import { DesignSchema, DesignSchemaWithPreview } from '../design.schema'
import { HomeComponent } from '../home/home.component'
import { ExportMode, ParkourCanvas } from "../parkour-canvas/parkour-canvas"
import { ParkourConfig } from '../parkour.config'
import { AuthService } from '../services/auth.service'
import { LimitsService } from '../services/limits.service'
import { StorageService } from '../services/storage.service'
import { UserProfile, UserService } from '../services/user.service'
import { showErrorBox, showInfoBox, showSuccessBox } from '../utils'
import { Sorter, SortMode } from '../sorter'

type DisplayMode = {
    icon: string,
}

type DisplayEvent = {
    eventName: string,
    designs: DesignSchemaWithPreview[],
    expanded?: boolean,
    eventStartDate?: Date,
    eventEndDate?: Date,
    eventDatesRange?: string,
    updatedAtDate?: Date,
}

@Component({
    selector: 'app-my-designs-list',
    standalone: true,
    imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ButtonModule,
    DropdownModule,
    SkeletonModule,
    TooltipModule,
    InputTextModule,
    MenuModule,
    TableModule,
    DialogModule,
    DragDropModule,
    ConfirmDialogModule,
    AutoSizeInputModule,
    ParkourCanvas,
],
    providers: [
        ConfirmationService
    ],
    templateUrl: './my-designs-list.component.html',
    styleUrls: ['./my-designs-list.component.scss'],
})
export class MyDesignsListComponent implements OnInit, OnDestroy {
    private subs: Subscription = new Subscription()

    @Output() events = new EventEmitter<any[]>()
    @Output() preview = new EventEmitter<string>()
    @ViewChild('canvas') canvas?: ParkourCanvas

    user: User | null = null

    eventsWithDesigns: DisplayEvent[] = []
    designs: DesignSchemaWithPreview[] = []
    selectedEvent: DisplayEvent | null = null
    designPreviewVisible: boolean = false
    designPreviewUrl: string = ''

    readonly inProgressMessage = $localize`Ładowanie listy...`
    listMsg: string = ''
    loadingList = false
    loadSub?: Subscription

    searchPanelVisible = false
    searchText: string = ''

    eventMenuItems: MenuItem[] = []
    displayMode: number = 0
    tileDragged?: DesignSchema
    tileDropped: boolean = false
    designsBackup?: DesignSchema[]
    custom: SortMode<DesignSchema>
    useDragHandles: boolean

    displayModes: DisplayMode[] = [
        {
            icon: "fa-solid fa-grip",
        },
        {
            icon: "fa-solid fa-list",
        },
    ]

    competitionSorter: Sorter<DesignSchema>
    eventSorter: Sorter<DisplayEvent>

    renameVisible: boolean = false
    newEventName: string = ''

    previewGenerationCfg: ParkourConfig    
    private previewGenerator: Subject<DesignSchemaWithPreview> = new Subject()

    constructor(
        private router: Router,
        private auth: AuthService,
        private store: StorageService,
        private msgSvc: MessageService,
        public userService: UserService,
        private home: HomeComponent,
        public deviceInfo: AngularDeviceInformationService,
        private confirmationService: ConfirmationService,
        private limitsService: LimitsService,
        @Inject(LOCALE_ID) private locale: string
    ) {
        this.previewGenerationCfg = new ParkourConfig(this.limitsService, {})
        this.useDragHandles = !this.deviceInfo.isDesktop()
        this.eventMenuItems = [{
            label: $localize`Zmień nazwę`,
            icon: 'fa-regular fa-pen-to-square',
            command: (event: MenuItemCommandEvent) => {
                if (this.selectedEvent) {
                    this.newEventName = this.selectedEvent?.eventName
                    this.renameVisible = true
                }
            }
        }, {
            label: $localize`Usuń`,
            icon: 'pi pi-trash',
            command: (event: MenuItemCommandEvent) => {
                this.confirmationService.confirm({
                    target: event.originalEvent?.target as EventTarget,
                    message: $localize`Czy na pewno chcesz usunąć wydarzenie i jego wszystkie projekty?`,
                    header: $localize`Usuwanie wydarzenia`,
                    icon: 'pi pi-exclamation-triangle',
                    acceptIcon: 'pi pi-check',
                    rejectIcon: 'pi pi-times',
                    acceptButtonStyleClass: 'p-button-danger gap-2',
                    rejectButtonStyleClass: 'p-button-text gap-2',
                    closeOnEscape: true,
                    defaultFocus: 'reject',
                    accept: () => {
                        this.deleteAllDesigns()
                    },
                    reject: () => {
                    }
                });
            }
        }]

        this.competitionSorter = new Sorter(
            () => this.selectedEvent?.designs || [], [
            new SortMode($localize`Sortuj projekty według nazwy`, 
                'fa-solid fa-arrow-down-a-z',
                'fa-solid fa-arrow-down-z-a',
                true,
                (a: DesignSchema, b: DesignSchema, dirAsc: boolean) => {
                    const c = a.title.localeCompare(b.title)
                    return dirAsc ? c : -c
                },
                undefined,
                true
            ),
            new SortMode($localize`Sortuj projekty według daty konkursu`,
                'fa-solid fa-arrow-down-1-9',
                'fa-solid fa-arrow-down-9-1',
                false,
                (a: DesignSchema, b: DesignSchema, dirAsc: boolean) => Sorter.sortDates(a.eventDate, b.eventDate, dirAsc)
            ),
            this.custom = new SortMode($localize`Sortuj projekty według kolejności zdefiniowanej przez użytkownika`,
                'fa-solid fa-arrow-down-short-wide',
                'fa-solid fa-arrow-down-short-wide',
                true,
                (a: DesignSchema, b: DesignSchema) => {
                    if (!a.userIndex && !b.userIndex) {
                        return b.updatedAt.localeCompare(a.updatedAt)
                    } else if (!a.userIndex) {
                        return -1
                    } else if (!b.userIndex) {
                        return 1
                    }
                    return a.userIndex - b.userIndex
                },
                undefined,
                false,
                { custom: true }
            )
        ])

        this.eventSorter = new Sorter(
            () => this.eventsWithDesigns, [
            new SortMode($localize`Sortuj wydarzenia według nazwy`, 
                'fa-solid fa-arrow-down-a-z',
                'fa-solid fa-arrow-down-z-a',
                true,
                (a: DisplayEvent, b: DisplayEvent, dirAsc: boolean) => {
                    const c = a.eventName.localeCompare(b.eventName)
                    return dirAsc ? c : -c
                },
            ),
            new SortMode($localize`Sortuj wydarzenia według daty ostatniej zmiany w projektach`, 
                'fa-solid fa-arrow-down-1-9',
                'fa-solid fa-arrow-down-9-1',
                false,
                (a: DisplayEvent, b: DisplayEvent, dirAsc: boolean) => Sorter.sortDates(a.updatedAtDate, b.updatedAtDate, dirAsc),
                undefined,
                true
            ),
            new SortMode($localize`Sortuj wydarzenia według daty`, 
                'fa-solid fa-arrow-down-1-9',
                'fa-solid fa-arrow-down-9-1',
                false,
                (a: DisplayEvent, b: DisplayEvent, dirAsc: boolean) => Sorter.sortDates(a.eventStartDate, b.eventStartDate, dirAsc)
            )
        ])
        
        this.subs.add(this.previewGenerator.pipe(
            concatMap(d => {
                if (!this.canvas) {
                    return throwError(() => new Error('canvas unavailable'))
                }
                return zip(this.canvas.loadDesign(d).pipe(
                    timeout(5000),
                    catchError(err => {
                        return EMPTY
                    })
                ), of(d))
            }),
            mergeMap(([, d]) => {
                if (!this.canvas) {
                    return throwError(() => new Error('canvas unavailable'))
                }
                this.canvas.resetView()
                return zip(this.canvas.exportToPng(ExportMode.SNAPSHOT, 1024, true, true, false), of(d))
            }),
        ).subscribe({
            next: ([png, d]) => {
                if (!d.png && png.data) {
                    console.log('generated preview for design', d.localId)
                    d.png = png.data
                    this.store.savePngPreview(d)
                }
            },
            error: () => {
                console.log('error generating preview')
            }
        }))       
    }

    ngOnInit(): void {
        this.subs.add(
            this.auth.user.subscribe({
                next: (aUser: User | null) => {
                    if (!aUser) {
                        this.user = aUser
                        return
                    }
                    if (!this.user) {
                        this.user = aUser
                        this.loadLocalDesigns()
                    } else {
                        this.user = aUser
                    }
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie informacji o użytkowniku`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
        this.userService.getUserProfile().subscribe({
            next: (userProfile: UserProfile | null | undefined) => {
                if (userProfile) {
                    let mode = userProfile.compDisplayMode
                    if (mode !== undefined) {
                        if (mode < 0 || mode > this.displayModes.length) {
                            mode = 0
                        }
                        this.displayMode = mode
                    }
                    if (userProfile.compSorter) {
                        this.competitionSorter.fromJson(userProfile.compSorter)
                    }
                    if (userProfile.eventSorter) {
                        this.eventSorter.fromJson(userProfile.eventSorter)
                    }
                }
            }
        })
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
        if (this.loadSub) {
            this.loadSub.unsubscribe()
            this.loadSub = undefined
        }
    }

    renameEvent() {
        if (this.newEventName) {
            this.renameEventInDesigns(this.newEventName)
        }
        this.renameVisible = false
        this.newEventName = ''
    }

    cancelRenameEvent() {
        this.renameVisible = false
        this.newEventName = ''
    }

    onTileDragStart(design: DesignSchema, event: DragEvent) {
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = "move"
            event.dataTransfer.effectAllowed = "move"
        }
        this.tileDragged = design
        this.tileDropped = false
        this.designsBackup = this.selectedEvent?.designs.slice()
    }

    onTileDragEnd() {
        if (this.selectedEvent && this.designsBackup && !this.tileDropped) {
            this.selectedEvent.designs = this.designsBackup
        }
        this.tileDragged = undefined
        this.tileDropped = false
    }

    onTileDropEnter(design: DesignSchema, event: DragEvent) {
        const designs = this.selectedEvent?.designs
        if (designs && this.tileDragged && design !== this.tileDragged) {
            const idxFrom = designs.indexOf(this.tileDragged)
            const idxTo = designs.indexOf(design)
            if (idxFrom >= 0) {
                designs.splice(idxFrom, 1)
            }
            if (idxTo >= 0) {
                designs.splice(idxTo, 0, this.tileDragged)
            }
        }
        event.preventDefault()
    }

    onTileDropOver(event: DragEvent) {
        event.preventDefault()
    }

    onTileDrop(design: DesignSchema) {
        this.tileDropped = true
        this.compRowReorder()
    }

    createNewDesignInEvent() {
        this.home.showNewDesignDialog({
            eventName: this.selectedEvent?.eventName || ''
        })
    }

    toggleDisplayMode() {
        this.displayMode = (this.displayMode + 1) % this.displayModes.length
        const patch: UserProfile = {
            compDisplayMode: this.displayMode,
        }
        this.userService.updateUserProfile(patch)
    }

    competitionSort(mode: SortMode<DesignSchema>) {
        this.competitionSorter.toggleSort(mode)
        const patch: UserProfile = {
            compSorter: this.competitionSorter.toJson()
        }
        this.userService.updateUserProfile(patch)
    }

    eventSort(mode: SortMode<DisplayEvent>) {
        this.eventSorter.toggleSort(mode)
        const patch: UserProfile = {
            eventSorter: this.eventSorter.toJson()
        }
        this.userService.updateUserProfile(patch)
    }

    compRowReorder() {
        this.selectedEvent?.designs.forEach((d, i) => {
            if (d.userIndex !== i + 1) {
                d.userIndex = i + 1
                this.store.updateDesign(d)
            }
        })
        this.competitionSorter.sort(this.custom)
    }

    prepareEventsList() {
        let selectedEventSpotted = false

        const events = []
        this.eventsWithDesigns = []
        for (const d of this.designs) {
            if (this.searchText) {
                const t = this.searchText.toLowerCase()
                if ((d.eventDate && d.eventDate.toISOString().toLowerCase().includes(t)) ||
                    (d.eventName && d.eventName.toLowerCase().includes(t)) ||
                    (d.title && d.title.toLowerCase().includes(t)) ||
                    (d.authorName && d.authorName.toLowerCase().includes(t)) ||
                    (d.shortDescr && d.shortDescr.toLowerCase().includes(t)) ||
                    (d.nationalRules && d.nationalRules.toLowerCase().includes(t)) ||
                    (d.feiRules && d.feiRules.toLowerCase().includes(t))) {
                    //ok
                } else {
                    continue
                }
            }

            let event: DisplayEvent | undefined
            if (d.eventName) {
                event = events.find(v => v.eventName === d.eventName)
                if (!event) {
                    event = {
                        eventName: d.eventName,
                        designs: [],
                        expanded: false,
                    }
                    this.eventsWithDesigns.push(event)
                    events.push(event)
                }
                event.designs.push(d)
            } else {
                // design with empty event
                event = {
                    eventName: d.title,
                    designs: [d],
                }
                this.eventsWithDesigns.push(event)
            }

            if (!selectedEventSpotted && this.selectedEvent) {
                if (event.eventName === this.selectedEvent.eventName) {
                    selectedEventSpotted = true
                    this.selectedEvent = event
                }
            }
        }
        if (!selectedEventSpotted) {
            this.selectedEvent = null
        }

        // conclude event start, end and modifiedAt dates
        for (let ev of this.eventsWithDesigns) {
            let dCnt = 0
            for (let d of ev.designs) {
                dCnt += 1
                if (d.eventDate) {
                    if (ev.eventStartDate) {
                        if (d.eventDate < ev.eventStartDate) {
                            ev.eventStartDate = d.eventDate
                        }
                    } else {
                        ev.eventStartDate = d.eventDate
                    }
                    if (ev.eventEndDate) {
                        if (d.eventDate > ev.eventEndDate) {
                            ev.eventEndDate = d.eventDate
                        }
                    } else {
                        ev.eventEndDate = d.eventDate
                    }
                }
                if (d.updatedAt) {
                    const date = new Date(d.updatedAt)
                    if (!ev.updatedAtDate || ev.updatedAtDate && ev.updatedAtDate < date) {
                        ev.updatedAtDate = date
                    }
                }
            }

            // sort event's designs
            ev.designs.sort((a: DesignSchema, b: DesignSchema) => a.title.localeCompare(b.title));
        }
        // conclude event dates range
        for (let ev of this.eventsWithDesigns) {
            ev.eventDatesRange = ''
            if (ev.eventStartDate && ev.eventEndDate) {
                let left = ''
                let right = ''

                left += ev.eventStartDate.getFullYear()
                if (ev.eventStartDate.getFullYear() !== ev.eventEndDate.getFullYear()) {
                    right += '-' + ev.eventEndDate.getFullYear()
                }

                left += '.' + (ev.eventStartDate.getMonth() + 1).toFixed(0).padStart(2, '0')
                if (ev.eventStartDate.getMonth() !== ev.eventEndDate.getMonth()) {
                    if (right === '') {
                        right += '-'
                    } else {
                        right += '.'
                    }
                    right += (ev.eventEndDate.getMonth() + 1).toFixed(0).padStart(2, '0')
                }

                left += '.' + ev.eventStartDate.getDate().toFixed(0).padStart(2, '0')
                if (ev.eventStartDate.getDate() !== ev.eventEndDate.getDate()) {
                    if (right === '') {
                        right += '-'
                    } else {
                        right += '.'
                    }
                    right += ev.eventEndDate.getDate().toFixed(0).padStart(2, '0')
                }
                ev.eventDatesRange = left + right
            }
        }

        // update selected event
        if (this.eventsWithDesigns.length === 0) {
            this.selectedEvent = null
        } else {
            if (!this.selectedEvent) {
                this.selectedEvent = this.eventsWithDesigns[0]
            }
        }
        this.eventSorter.sort()
        this.competitionSorter.sort()

        this.events.emit(events)
    }

    loadLocalDesigns() {
        this.subs.add(
            this.store.getLocalDesigns().subscribe({
                next: (designs: DesignSchema[]) => {
                    this.listMsg = ''
                    this.designs = designs
                    this.prepareEventsList()

                    this.loadDesignsList()
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie lokalnych projeków`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    loadDesignsList() {
        this.loadingList = true
        if (this.designs.length === 0) {
            this.listMsg = this.inProgressMessage
        } else {
            this.listMsg = ''
        }
        if (this.loadSub) {
            this.loadSub.unsubscribe()
            this.loadSub = undefined
        }
        this.loadSub = this.store.loadMyDesigns(1000, 'updatedAt', 'desc').subscribe({
            next: (designs: DesignSchemaWithPreview[]) => {
                this.loadingList = false
                // copy over existing preview references to the loaded list, because the previews
                // are being loaded asynchronously this prevents preview blinking upon loading
                for (let d of this.designs) {
                    const f = designs.find(f => f.remoteId === d.remoteId)
                    if (f) {
                        if (d.pngUrl && d.pngUrl !== 'progress') {
                            f.pngUrl = d.pngUrl
                        }
                        if (d.png) {
                            f.png = d.png
                        }
                    }
                }
                this.designs = designs
                this.prepareEventsList()

                if (this.designs.length === 0) {
                    this.listMsg = $localize`Nie masz jeszcze żadnego projektu. Kliknij "Nowy konkurs" aby stworzyć nowy projekt.`
                } else {
                    this.listMsg = ''
                    setTimeout(() => {
                        this.generateMissingPreviews()
                    }, 5000)
                }
            },
            error: (err) => {
                this.loadingList = false
                this.listMsg = $localize`Pobieranie projektów nie powiodło się`
                console.error('error occured', err)
                showErrorBox(this.msgSvc, $localize`Pobieranie Twoich projektów`, $localize`Wystąpił nieznany błąd`)
            }
        })
    }

    generateMissingPreviews() {
        this.designs.filter(d => !d.pngUrl && !d.png).forEach(d => {
            this.previewGenerator.next(d)
        })
    }

    editDesign(d: DesignSchema) {
        this.router.navigate(['/designer'], { queryParams: { id: d.localId, author: d.author } })
    }

    cloneDesign(oldDesign: DesignSchema) {
        const [localId, _, obs] = this.store.cloneDesign(oldDesign)

        // IMPORTANT: do not put this subscription to subs because in case of navigate to designer page
        // it will be canceled in ngOnDestroy
        obs.subscribe({
            next: (docRef: DesignSchema) => {
                showSuccessBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Projekt zapisano zdalnie, na serwerze`)
            },
            error: (err: DesignSchema) => {
                console.error("Error adding design: ", err)
                showErrorBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Wystąpił nieznany błąd`)
            }
        })
        showInfoBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Projekt zapisano lokalnie`)
        this.router.navigate(['/designer'], { queryParams: { id: localId } })
    }

    deleteDesign(design: DesignSchema) {
        for (let i = 0; i < this.designs.length; i++) {
            if (this.designs[i].localId === design.localId) {
                this.designs.splice(i, 1)
                showInfoBox(this.msgSvc, $localize`Usuwanie projektu`, $localize`Projekt jest usuwany`)
                this.prepareEventsList()
                break
            }
        }
        this.subs.add(
            this.store.deleteDesign(design).subscribe({
                next: (ok) => {
                    showSuccessBox(this.msgSvc, $localize`Usuwanie projektu`, $localize`Projekt został usunięty`)
                    this.loadDesignsList()
                },
                error: (error) => {
                    console.error("Error deleting design: ", error)
                    showErrorBox(this.msgSvc, $localize`Usuwanie projektu`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    onImageError(design: DesignSchemaWithPreview) {
        design.pngUrl = undefined
    }

    onImageLoad(ev: Event, design: DesignSchemaWithPreview) {
        const img = ev.target
        if (img instanceof HTMLImageElement) {
            const canvas = document.createElement("canvas");
            canvas.width = img.naturalWidth
            canvas.height = img.naturalHeight
            const ctx = canvas.getContext("2d");
            ctx!.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
            const dataURL = canvas.toDataURL("image/png");
            this.store.savePngPreviewLocally(design.localId, dataURL)
        }
    }

    handlePreview(d: DesignSchemaWithPreview) {
        let previewPngUrl = ''

        if (d.pngUrl) {
            previewPngUrl = d.pngUrl
        } else if (d.png) {
            previewPngUrl = d.png
        }

        this.preview.emit(previewPngUrl)
    }

    expandEventPanel(event: DisplayEvent) {
        event.expanded = !event.expanded
    }

    showEventDesigns(event: DisplayEvent) {
        this.selectedEvent = event
        this.competitionSorter.sort()
    }

    doSearch(event: KeyboardEvent) {
        if (event.key === "Escape") {
            this.searchText = ''
            this.searchPanelVisible = false
        }
        this.prepareEventsList()
    }

    deleteAllDesigns() {
        if (!this.selectedEvent || this.selectedEvent.designs.length === 0) {
            return
        }

        showInfoBox(this.msgSvc, $localize`Usuwanie wydarzenia`, $localize`Wydarzenie i jego wszystkie projekty są usuwane`)

        let count = this.selectedEvent.designs.length
        for (const design of this.selectedEvent.designs) {
            this.subs.add(
                this.store.deleteDesign(design).subscribe({
                    next: (ok) => {
                        count -= 1
                        if (count === 0) {
                            showSuccessBox(this.msgSvc, $localize`Usuwanie wydarzenia`, $localize`Projekty wydarzenia zostały usunięte`)
                            this.loadDesignsList()
                        }
                    },
                    error: (error) => {
                        console.error("Error deleting design: ", error)
                        showErrorBox(this.msgSvc, $localize`Usuwanie wydarzenia`, $localize`Wystąpił nieznany błąd`)
                    }
                })
            )
        }
    }

    renameEventInDesigns(name: string) {
        if (!name || !this.selectedEvent || this.selectedEvent.designs.length === 0) {
            return
        }
        let count = this.selectedEvent.designs.length
        for (const design of this.selectedEvent.designs) {
            design.eventName = name
            this.subs.add(
                this.store.updateDesign(design).subscribe({
                    next: (ok) => {
                        count -= 1
                        if (count === 0) {
                            showSuccessBox(this.msgSvc, $localize`Zmiana nazwy wydarzenia`, $localize`Nazwa wydarzenia została zmieniona`)
                            this.prepareEventsList()
                        }
                    },
                    error: (error) => {
                        console.error("Error saving design when renaming event:", error)
                        showErrorBox(this.msgSvc, $localize`Zmiana nazwy wydarzenia`, $localize`Wystąpił nieznany błąd`)
                    }
                })
            )
        }
    }
}
