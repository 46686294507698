import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { Subscription, distinct } from 'rxjs'
import { MessageService, PrimeNGConfig } from 'primeng/api';

import { AuthService } from './services/auth.service';
import { ElectronService } from './core/services';
import { APP_CONFIG } from '../environments/environment';
import { getBuildVersion, getBuildDate, showErrorBox, showInfoBox } from './utils';
import { UserSubscriptionService } from './services/user-subscription.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private subs: Subscription = new Subscription()

    version: string = ''
    buildDate: string = ''

    constructor(
        private electronService: ElectronService,
        protected auth: AuthService,
        private userSubscriptionService: UserSubscriptionService,
        private router: Router,
        private msgSvc: MessageService,
        private updates: SwUpdate,
        private primeNgConfig: PrimeNGConfig,
    ) {
        console.log('APP_CONFIG', APP_CONFIG);

        this.version = getBuildVersion()
        this.buildDate = getBuildDate()

        if (electronService.isElectron) {
            console.log(process.env);
            console.log('Run in electron');
            console.log('Electron ipcRenderer', this.electronService.ipcRenderer);
            console.log('NodeJS childProcess', this.electronService.childProcess);
        } else {
            console.log('Run in browser');
        }

        // detect app updates and ask user for reloading
        this.subs.add(
            this.updates.versionUpdates.subscribe({
                next: evt => {
                    switch (evt.type) {
                        case 'VERSION_DETECTED':
                            console.info('Downloading new app version', evt.version)
                            break;
                        case 'VERSION_READY':
                            console.info('Current app version', evt.currentVersion)
                            console.info('New app version ready for use', evt.latestVersion)
                            // ask user for reload
                            this.msgSvc.add({
                                key: 'update-confirm',
                                severity: 'info',
                                life: 20000,
                            });
                            break;
                        case 'VERSION_INSTALLATION_FAILED':
                            console.error('Failed to install app version', evt.version, ' error:', evt.error)
                            break;
                    }
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie informacji o wersji aplikacji`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )

        this.subs.add(
            this.userSubscriptionService.getPlanState().pipe(distinct()).subscribe({
                next: (planState: string) => {
                    console.info('plan state:', planState)
                    if (planState === 'trial-begin') {
                        showInfoBox(this.msgSvc, 
                            $localize`Początek okresu próbnego`,
                            $localize`Właśnie rozpoczął się okres próbny. Masz dostęp do pełnej funkcjonalności Parkour.Design przez następne 2 tygodnie.`)
                    }
                    if (planState === 'trial-end') {
                        this.router.navigate(['/user-plan'])
                        showInfoBox(this.msgSvc, 
                            $localize`Koniec okresu próbnego`,
                            $localize`Twój okres próbny się zakończył. Wybierz odpowiedni dla Ciebie plan.`)
                    }
                },
                error: (err: any) => {
                    console.error('error occured', err)
                }
            })
        )
    }

    onUpdateReject() {
        this.msgSvc.clear('update-confirm')
    }

    onUpdateConfirm() {
        document.location.reload()
    }

    ngOnInit(): void {
        this.primeNgConfig.setTranslation({
            weak: $localize`Słabe`,
            medium: $localize`Średnie`,
            strong: $localize`Silne`,
            passwordPrompt: $localize`Podaj hasło`
        })
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }
    getLocationPath() {
        return window.location.pathname
    }
}
