import { Injectable } from '@angular/core';

import { User } from '@angular/fire/auth'
import { BehaviorSubject, switchMap, mergeMap, of, Observable, first } from 'rxjs'

import { UserService } from './user.service';
import { AuthService } from './auth.service'
import { StripeService } from '../services/stripe.service';
import { PaddleService } from '../services/paddle.service';

import { timezoneToCurrency } from './timezonemap'
import { PaymentProvider, SubscriptionPlan } from './subscription-plans';
import { SubscriptionLevel } from '../design.schema';

type PaymentProviderService = StripeService | PaddleService | undefined

@Injectable({
    providedIn: 'root'
})
export class PayService {
    user?: User
    providerName = ''
    _provider?: PaymentProviderService
    private provider = new BehaviorSubject<PaymentProviderService>(undefined)

    constructor(
        protected auth: AuthService,
        private userService: UserService,
        private stripe: StripeService,
        private paddle: PaddleService,
    ) {

        this.auth.user.subscribe({
            next: (aUser: User | null) => {
                if (!aUser) {
                    if (this._provider) {
                        this._provider.finit()
                        this._provider = undefined
                        this.provider.next(this._provider)
                    }
                    return
                }
                this.user = aUser
                this._getUserProfile()
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
    }

    initPaymentProvider(paymentProvider: string) {
        this.providerName = paymentProvider.charAt(0).toUpperCase() + paymentProvider.slice(1)
        if (paymentProvider === PaymentProvider.PADDLE) {
            this._provider = this.paddle
        } else if (paymentProvider === PaymentProvider.STRIPE) {
            this._provider = this.stripe
        } else {
            console.error('unsupported provider', paymentProvider)
            return
        }
        this._provider.init(this.user!)
        this.provider.next(this._provider)
    }

    private _getUserProfile() {
        this.userService.getUserProfile().subscribe({
            next: (profile) => {
                if (profile && profile.paymentProvider) {
                    this.initPaymentProvider(profile.paymentProvider)
                }
            },
            error: (err) => {
                console.error('Error fetching user profile:', err);
            }
        });
    }

    getCurrentCurrecy() {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        const cityArr = tz.split("/")
        const city = cityArr[cityArr.length - 1]
        const currency = timezoneToCurrency[city]
        return currency
    }

    getSubscriptionPlan(): Observable<SubscriptionPlan | undefined> {
        return this.provider.pipe(
            switchMap((p: PaymentProviderService) => {
                if (!this._provider) {
                    // if provider is not yet initialized or no subscription is present
                    // then starter plan should be used
                    const plan: SubscriptionPlan = { name: SubscriptionLevel.STARTER }
                    return of(plan)
                }
                return this._provider?.getSubscriptionPlan()
            })
        )
    }

    subscribeToPlan(plan: string, interval: string) {
        return this.provider.pipe(
            switchMap((p: PaymentProviderService) => {
                if (!this._provider) {
                    throw new Error('Payment provider is not initialized')
                }
                return this._provider?.subscribeToPlan(plan, interval)
            })
        )
    }

    teleportToCustomerPortal() {
        return this.provider.pipe(first()).subscribe({
            next: () => {
                return this._provider?.teleportToCustomerPortal()
            },
            error: (err) => {
                console.error('Error getting payment provider:', err);
            }
        })
    }
}
