import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs'
import { Analytics, logEvent } from '@angular/fire/analytics'
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';

import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { PayService } from '../services/pay.service';
import { UserSubscriptionService } from '../services/user-subscription.service';
import { showErrorBox, showInfoBox, showSuccessBox } from '../utils'


@Component({
    selector: 'app-pricing-panel',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        SelectButtonModule,
        ButtonModule,
        DialogModule,
        DropdownModule,
        RadioButtonModule,
    ],
    templateUrl: './pricing-panel.component.html',
    styleUrl: './pricing-panel.component.scss'
})
export class PricingPanelComponent implements OnInit, OnDestroy {
    public subs: Subscription = new Subscription()

    userProfile?: any

    pricingIntervalOptions: any[] = [
        { name: $localize`Miesięcznie`, value: 'monthly' },
        { name: $localize`Rocznie`, value: 'yearly' },
    ]
    pricingIntervalOption = 'yearly'

    currentPlan?: any
    newPlan = ''

    starterPlanLoading = false
    advancedPlanLoading = false
    mastersPlanLoading = false

    currencies: any[] = [
        { value: 'AUD', name: 'AUD (Australian dollar)' },
        { value: 'CHF', name: 'CHF (Swiss franc)' },
        { value: 'DKK', name: 'DKK (Danish krone)' },
        { value: 'EUR', name: 'EUR (Euro)' },
        { value: 'GBP', name: 'GBP (British pound)' },
        { value: 'INR', name: 'INR (Indian rupee)' },
        { value: 'NOK', name: 'NOK (Norwegian krone)' },
        { value: 'NZD', name: 'NZD (New Zealand dollar)' },
        { value: 'PLN', name: 'PLN (Polish złoty)' },
        { value: 'SEK', name: 'SEK (Swedish krona)' },
        { value: 'USD', name: 'USD (US dollar)' },
    ]
    selectedCurrency = this.currencies[0].value

    pricing: { [id: string]: { [id: string]: { [id: string]: string; }; }; } = {
        advanced: {
            monthly: {
                EUR: '14.99', AUD: '23.99', CHF: '14.99', DKK: '111.99', GBP: '12.99', INR: '1299', NOK: '171.99', NZD: '26.99', PLN: '64.99', SEK: '169.99', USD: '15.99',
            },
            yearly: {
                EUR: '149.99', AUD: '239.99', CHF: '149.99', DKK: '1119.99', GBP: '129.99', INR: '12999', NOK: '1719.99', NZD: '269.99', PLN: '649.99', SEK: '1699.99', USD: '159.99',
            }
        },
        masters: {
            monthly: {
                EUR: '24.99', AUD: '39.99', CHF: '23.99', DKK: '185.99', GBP: '20.99', INR: '2199', NOK: '285.99', NZD: '43.99', PLN: '107.99', SEK: '283.99', USD: '26.99',
            },
            yearly: {
                EUR: '249.99', AUD: '399.99', CHF: '239.99', DKK: '1859.99', GBP: '209.99', INR: '21999', NOK: '2859.99', NZD: '439.99', PLN: '1079.99', SEK: '2839.99', USD: '269.99',
            }
        }
    }

    // customer type selection dialog
    customerTypeDlgVisible = false
    customerType = 'consumer'

    constructor(
        protected auth: AuthService,
        private userService: UserService,
        protected pay: PayService,
        private analytics: Analytics,
        private msgSvc: MessageService,
        private route: ActivatedRoute,
        private router: Router,
        private userSubscription: UserSubscriptionService,
    ) {
    }

    ngOnInit(): void {
        this.getUserProfile()

        this.subs.add(
            this.pay.getSubscriptionPlan().subscribe({
                next: (plan: any) => {
                    this.currentPlan = plan
                    if (plan && plan.name != 'starter') {
                        this.pricingIntervalOption = plan.interval
                    }
                    this.tryToChoosePlanAutomatically()
                },
                error: (err: any) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie informacji o planach cenowych`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )

        this.selectedCurrency = this.pay.getCurrentCurrecy()
        if (!this.selectedCurrency) {
            this.selectedCurrency = 'USD'
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    tryToChoosePlanAutomatically() {
        if (this.userProfile && !this.userProfile.planSelected &&
            this.currentPlan?.name == 'starter' &&
            ['starter', 'advanced', 'masters'].includes(this.route.snapshot.queryParams.plan) &&
            ['monthly', 'yearly'].includes(this.route.snapshot.queryParams.interval)) {

            this.pricingIntervalOption = this.route.snapshot.queryParams.interval
            this.choosePlan(this.route.snapshot.queryParams.plan)
        }
    }

    getUserProfile() {
        this.userService.getUserProfile().subscribe({
            next: (userProfile: any) => {
                this.userProfile = userProfile
                if (this.userProfile) {
                    this.tryToChoosePlanAutomatically()
                }
            },
            error: (err) => {
                console.error('error occured', err)
                showErrorBox(this.msgSvc, $localize`Pobieranie informacji o użytkowniku`, $localize`Wystąpił nieznany błąd`)
            }
        })
    }

    isTrial() {
        return this.userSubscription.isTrial(this.userProfile)
    }

    markStarterPlanSelectedInUserProfile() {
        const trialEnd = new Date(Date.now()).toISOString()
        this.userService.updateUserProfile({ trialEnd: trialEnd, planSelected: true }).subscribe({
            next: (resp: any) => {
                this.starterPlanLoading = false
                showSuccessBox(this.msgSvc, $localize`Plan został ustawiony`, $localize`Plan cenowy został zaktualizowany.`)
                this.router.navigate(['/'])
            },
            error: (err) => {
                this.starterPlanLoading = false
                console.error('error occured', err)
                showErrorBox(this.msgSvc, $localize`Wybór plan nie powiódł się`, $localize`Wystąpił nieznany błąd`)
            }
        })
    }

    choosePlan(plan: string) {
        this.newPlan = plan
        if (plan === 'starter') {
            this.markStarterPlanSelectedInUserProfile()
        } else {
            if (!this.currentPlan || this.currentPlan?.name === 'starter') {
                this.customerTypeDlgVisible = true
            } else {
                this.subscribeToPlan()
            }
        }

        logEvent(this.analytics, 'open_buy', { plan: plan, interval: this.pricingIntervalOption })
    }

    chooseCustomerType() {
        this.customerTypeDlgVisible = false
        if (this.customerType === 'consumer') {
            this.pay.initPaymentProvider('paddle')
        } else {
            this.pay.initPaymentProvider('stripe')
        }
        this.subscribeToPlan()
    }

    subscribeToPlan() {
        if (this.newPlan === 'starter') {
            this.starterPlanLoading = true
        } else if (this.newPlan === 'advanced') {
            this.advancedPlanLoading = true
        } else {
            this.mastersPlanLoading = true
        }

        showInfoBox(this.msgSvc, $localize`Przekierowanie`, $localize`Przekierowanie do operatora płatoności` + ' ' + this.pay.providerName)

        this.pay.subscribeToPlan(this.newPlan, this.pricingIntervalOption).subscribe({
            next: () => {
                this.starterPlanLoading = false
                this.advancedPlanLoading = false
                this.mastersPlanLoading = false
            },
            error: (err: any) => {
                this.starterPlanLoading = false
                this.advancedPlanLoading = false
                this.mastersPlanLoading = false
                console.error('error occured', err)
                showErrorBox(this.msgSvc, $localize`Subskrypcja nie powiodła się`, $localize`Wystąpił nieznany błąd`)
            }
        })
    }

    teleportToCustomerPortal(plan: string) {
        showInfoBox(this.msgSvc, $localize`Otwieranie portalu operatora`, $localize`Otwieranie nowej zakładki z portalem operatora` + ' ' + this.pay.providerName)
        this.pay.teleportToCustomerPortal()
    }
}
