import { Injectable } from '@angular/core';

import { User } from '@angular/fire/auth';
import { BehaviorSubject, Observable, Subscription, map, distinctUntilChanged } from 'rxjs'

import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { PdfOption, SorterSchema, UserRole } from '../design.schema';
import { ShortcutStore } from '../detail/detail.ui.shortcuts';

export type DesignDisplayOptions = { [id: string]: any }
export type PdfOptions = { [key in PdfOption]?: boolean }

export type UserProfile = {
    version?: number,
    uid?: string,
    name?: string,
    createdAt?: string,
    updatedAt?: string,
    lastLoginAt?: string,
    planSelected?: boolean,
    designDisplayOptions?: DesignDisplayOptions,
    shortcuts?: ShortcutStore[],
    undoLimit?: number,
    email?: string | null,
    attentionForStart?: boolean,
    photoURL?: string | null,
    subscriptionLevel?: string,
    role?: UserRole,
    newsletterConsent?: boolean,
    termsConsent?: boolean,
    testerPlan?: string,
    compDisplayMode?: number,
    compSorter?: SorterSchema,
    eventSorter?: SorterSchema,
    compSorterForAllEvents?: SorterSchema,
    pdfOptions?: PdfOptions,
    paymentProvider?: string,
    zoomWithWheel?: boolean,
    sourceAffiliate?: string,
    trialStart?: string,
    trialEnd?: string,
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    static readonly userProfileDefaults: UserProfile = {
        undoLimit: 20,
        newsletterConsent: true,
        zoomWithWheel: true,
        pdfOptions: {
            [PdfOption.MAIN_DESIGN]: true
        }
    }

    // current version of the user profile scheme
    readonly currentVersion = 1
    private user?: User
    private userProfile: UserProfile | undefined | null = null
    /**
     *  userProfileSubject:
     *  - UserProfile object when user is logged in and user profile exists;
     *  - undefined when user is logged in but user profile does not exist yet;
     *  - null when user is is not logged in.
     */
    private userProfileSubject = new BehaviorSubject<UserProfile | undefined | null>(null)

    private getUserProfileSub?: Subscription

    constructor(
        private store: StorageService,
        private auth: AuthService,
    ) {
        this.auth.remoteUser.subscribe({
            next: (user: User | null) => {
                if (user) {
                    this.user = user
                    this.userProfile = undefined
                    this.userProfileSubject.next(this.userProfile)
                    this._getUserProfile()
                } else {
                    // reset profile after logout
                    this.userProfile = null
                    this.userProfileSubject.next(this.userProfile)
                }
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
        this._getUserProfile()
    }

    updateUserProfile(userProfilePatch: UserProfile) {
        if (!this.userProfile) {
            this.userProfile = {}
        }
        Object.assign(this.userProfile, userProfilePatch)
        this.userProfile.version = this.currentVersion
        const p: any = this.userProfile as any
        if (p.dismissedTips) {
            delete p.dismissedTips
        }
        if (p.dissmissedTips) {
            delete p.dissmissedTips
        }
        if (p.toolTips) {
            delete p.toolTips
        }
        return this.store.setUserProfile(this.userProfile).pipe(
            map(up => {
                this.userProfileSubject.next(this.userProfile)
            })
        )
    }

    private _setUserProfile(patch: UserProfile) {
        this.updateUserProfile(patch).subscribe({
            next: () => {
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
    }

    private _getAffiliateCodeFromCookieOrUrl() {
        // check if affiliate code is in URL, if so then return it
        const urlParams = new URLSearchParams(window.location.search)
        const afref = urlParams.get('afref')
        if (afref) {
            return afref
        }

        // check if affiliate code is not in URL, the check if it is in a cookie and return it
        const value = "; " + document.cookie
        const parts = value.split("; affiliate_code=")
        if (parts.length === 2) {
            return parts.pop()?.split(";").shift()
        }
        return undefined
    }

    private _getUserProfile() {
        if (this.getUserProfileSub) {
            this.getUserProfileSub.unsubscribe()
        }
        this.getUserProfileSub = this.store.getUserProfile().subscribe({
            next: (userProfile) => {
                if (userProfile) {
                    this.userProfile = userProfile
                } else {
                    userProfile = this.userProfile = {}
                }

                const patch: UserProfile = {}
                if (!userProfile.email) {
                    patch.email = this.user!.email
                    patch.photoURL = this.user!.photoURL
                }
                if (this.user!.metadata.creationTime && this.userProfile?.createdAt !== this.user!.metadata.creationTime) {
                    patch.createdAt = this.user!.metadata.creationTime
                }
                if (this.user!.metadata.lastSignInTime && this.userProfile?.lastLoginAt !== this.user!.metadata.lastSignInTime) {
                    patch.lastLoginAt = this.user!.metadata.lastSignInTime
                }
                // set defaults for undefined user profile fields
                for (let i of Object.keys(UserService.userProfileDefaults)) {
                    if (!userProfile.hasOwnProperty(i)) {
                        (patch as any)[i] = (UserService.userProfileDefaults as any)[i]
                    }
                }
                // store afilliate code if present
                const sourceAffiliate = this._getAffiliateCodeFromCookieOrUrl()
                if (sourceAffiliate && !userProfile.sourceAffiliate) {
                    patch.sourceAffiliate = sourceAffiliate
                }

                if (Object.keys(patch).length > 0) {
                    this._setUserProfile(patch)
                } else {
                    this.userProfileSubject.next(this.userProfile)
                }
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
    }

    /**
     * Returns user profile.
     *
     * @returns UserProfile object is returned when user is logged in and user profile exists;
     *   undefined is returned when user is logged in but user profile does not exist yet;
     *   null is returned when user is is not logged in.
     */
    getUserProfile(): Observable<UserProfile | null | undefined> {
        return this.userProfileSubject.pipe(distinctUntilChanged())
    }
}
