import { AfterViewInit, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputNumber, InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { ColorPickerModule } from 'primeng/colorpicker';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';

import { ParkourObject } from '../parkour-objects/parkour-object'
import { DetailComponent } from '../detail.component';
import { LayerId } from '../../parkour-canvas/parkour-canvas'
import { CombinationType, Direction } from '../../design.schema'
import { Obstacle } from '../parkour-objects/obstacle'
import { PathObject } from '../parkour-objects/path-object'
import { ObstaclePathNode } from '../detail.path.node'
import { Orientation } from '../parkour-objects/banner'
import { LengthPipe } from '../../pipes'
import { ParkourObjectGroup } from '../detail.group'

@Component({
    selector: 'app-parkour-object-panel',
    standalone: true,
    imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    OverlayPanelModule,
    SplitButtonModule,
    InputNumberModule,
    CheckboxModule,
    InputTextModule,
    SelectButtonModule,
    TooltipModule,
    DropdownModule,
    RadioButtonModule,
    ColorPickerModule,
    TriStateCheckboxModule,
    InputTextareaModule,
    LengthPipe
],
    templateUrl: './parkour-object-panel.component.html',
    styleUrl: './parkour-object-panel.component.scss'
})
export class ParkourObjectPanelComponent implements AfterViewInit {
    @Input() obj!: ParkourObject
    @Input() view!: DetailComponent

    @ViewChild('textBoxInputArea') textBoxInputAreaEl?: ElementRef<HTMLInputElement>
    @ViewChild('nameInputArea') nameInputAreaEl?: ElementRef<HTMLInputElement>
    @ViewChildren('label') labelsEl?: QueryList<ElementRef<HTMLInputElement>>

    justificationOptions: any[] = [
        { icon: 'pi pi-align-left', value: 'left' },
        { icon: 'pi pi-align-center', value: 'center' },
        { icon: 'pi pi-align-right', value: 'right' }
    ]

    fontFamilies: any[] = [
        { name: 'Arial', value: 'Arial' }, // (sans-serif)
        { name: 'Brush Script MT', value: 'Brush Script MT' }, // (cursive)
        { name: 'Courier New', value: 'Courier New' }, // (monospace)
        { name: 'Garamond', value: 'Garamond' }, // (serif)
        { name: 'Georgia', value: 'Georgia' }, // (serif)
        { name: 'Roboto', value: 'Roboto' },
        { name: 'Tahoma', value: 'Tahoma' }, // (sans-serif)
        { name: 'Times New Roman', value: 'Times New Roman' }, // (serif)
        { name: 'Trebuchet MS', value: 'Trebuchet MS' }, // (sans-serif)
        { name: 'Verdana', value: 'Verdana' }, // (sans-serif)
    ]

    layerOptions = [
        { name: $localize`Pierwszy plan`, value: LayerId.FRONT },
        { name: $localize`Tło`, value: LayerId.BACKGROUND },
    ]

    orientationOptions = [
        { name: $localize`Wiersz`, value: Orientation.HORIZONTAL },
        { name: $localize`Kolumna`, value: Orientation.VERTICAL },
    ]

    userArrowDirectionOptions = [
        { name: $localize`Do przodu`, icon: 'assets/arrow-forward.png', value: Direction.forward },
        { name: $localize`Do tyłu`, icon: 'assets/arrow-backward.png', value: Direction.backward },
        { name: $localize`W obie strony`, icon: 'assets/arrow-both.png', value: Direction.both }
    ]

    tableTooltips = [
        $localize`Runda 1 do przodu`, $localize`Runda 1 do tyłu`, $localize`Runda 2 do przodu`, $localize`Runda 2 do tyłu`
    ]

    labelFocus: boolean[] = [false, false, false, false]
    backup?: string
    labelInputs: (HTMLInputElement | undefined)[] = [undefined, undefined, undefined, undefined]
    private _prevCombinationDist?: number = undefined
    readonly combinationDistanceStep: number = 0.1

    get distanceUnit(): string {
        return LengthPipe.getUnit(this.view.cfg.params.distanceUnit) || ''
    }

    combinationTypes = [
        {
            value: CombinationType.OPEN,
            label: $localize`Otwarty`
        }, {
            value: CombinationType.PARTIALLY_CLOSED,
            label: $localize`Częściowo zamknięty`    
        }, {
            value: CombinationType.CLOSED,
            label: $localize`Zamknięty`
        }
    ]

    ngAfterViewInit() {
        if (this.labelsEl) {
            const labels = this.labelsEl.toArray().map(e => e.nativeElement)
            if (labels.length === 4) {
                this.labelInputs = labels
            }
        }
    }

    selectTextInputArea() {
        this.textBoxInputAreaEl?.nativeElement.select()
    }

    selectObstacleNameInputArea() {
        this.nameInputAreaEl?.nativeElement.select()
    }

    selectLabelBox(roundNo: number, pass: number) {
        if (roundNo >= 1 && roundNo <= 2 && pass >= 1 && pass <= 2) {
            this.labelInputs[(roundNo - 1) * 2 + pass - 1]?.focus()
        }
    }

    onLabelFocus(i: number, o: Obstacle) {
        this.backup = o.manualLabels[i]
        this.labelFocus[i] = true
    }

    onLabelBlur(i: number) {
        this.labelFocus[i] = false
    }

    onLabelEscape(label: HTMLInputElement, i: number, o: Obstacle) {
        o.manualLabels[i] = this.backup || ''
        label.blur()
    }

    onLabelEnter(label: HTMLInputElement) {
        label.blur()
    }

    onLabelChange() {
        this.view.updatePath()
    }

    onLabelCommit() {
        this.view.updatePath()
        this.view.saveData()
    }

    getPathNode(o: PathObject, occurance: number, roundNo?: number): [ObstaclePathNode | null, number] {
        return this.view.canvas?.obstaclePath.getPathNode(o, occurance, roundNo) || [null, -1]
    }

    initCombinationDistance(o: ParkourObjectGroup) {
        this._prevCombinationDist = o.combinationDistance
    }

    updateCombinationDistance(o: ParkourObjectGroup, ev?: KeyboardEvent, fromModel?: boolean, cdInput?: InputNumber) {
        if (ev && ev.code !== 'Enter') {
            return
        }
        cdInput?.input.nativeElement.blur()
        let d = o.combinationDistance
        const delta = this._prevCombinationDist && d ? Math.round(Math.abs(this._prevCombinationDist - d) * 10) / 10 : undefined
        if (!this._prevCombinationDist ||
            !fromModel && this._prevCombinationDist !== d ||
            fromModel && (delta && (delta === this.combinationDistanceStep) || d === this.combinationDistanceStep)
        ) {
            o.setCombinationDistance(d)
            this.view.updatePath()
            this._prevCombinationDist = d
        }
    }
}
