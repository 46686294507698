import { SUUID } from "short-uuid"
import { ClassNo, CompetitionTable, LimitsService, ObstacleHeights } from "./services/limits.service"
import { DesignDisplayOptions } from "./services/user.service"

export enum EditMode {
    CONNECTOR = 0,
    DRAWING = 1
}

export enum UserRole {
    ADMIN = 'admin'
}

export enum SubscriptionLevel {
    FULL = 'full',
    STARTER = 'starter',
    ADVANCED = 'advanced',
    MASTERS = 'masters'
}

export type SorterModeSchema = {
    active: boolean,
    dirAsc: boolean,
}

export type SorterSchema = {
    modes: SorterModeSchema[]
}

export enum BannerPosition {
    NONE = 0,
    LEFT = 1,
    RIGHT,
    TOP,
    BOTTOM
}

export enum Direction {
    none = 0,
    forward = 1,
    backward = 2,
    both = 3
}

export type LimitRange = {
    min?: number,
    max?: number,
    default?: number,
    banned?: boolean
}

export type Speed = number

export enum CompetitionLocation {
    OUTDOOR = 'outdoor',
    INDOOR = 'indoor'
}

export enum Article {
    NONE = '',
    ART_238_GENERAL_TABLE_A = 'Art.238',
    ART_238_1_NOT_AGAINST_THE_CLOCK = 'Art.238.1',
    ART_238_1_1_NOT_AGAINST_THE_CLOCK = 'Art.238.1.1',
    ART_238_1_2_NOT_AGAINST_THE_CLOCK = 'Art.238.1.2',
    ART_238_1_3_NOT_AGAINST_THE_CLOCK = 'Art.238.1.3',
    ART_238_2_AGAINST_THE_CLOCK = 'Art.238.2',
    ART_238_2_1_AGAINST_THE_CLOCK = 'Art.238.2.1',
    ART_238_2_2_AGAINST_THE_CLOCK = 'Art.238.2.2',
    ART_238_2_3_AGAINST_THE_CLOCK = 'Art.238.2.3',
    ART_239_GENERAL_TABLE_C = 'Art.239',
    ART_261_NORMAL_AND_GP = 'Art.261',
    ART_262_POWER_AND_SKILL = 'Art.262',
    ART_262_2_PUISSANCE = 'Art.262.2',
    ART_262_3_SIX_BAR = 'Art.262.3',
    ART_262_4_MASTERS = 'Art.262.4',
    ART_263_HUNTING_HANDINESS = 'Art.263',
    ART_264_NATIONS_CUP = 'Art.264',
    ART_265_SPONSOR_AND_OTHER_TEAM = 'Art.265',
    ART_266_5_1_FAULT_AND_OUT_OBSTACLES = 'Art.266.5.1',
    ART_266_5_2_FAULT_AND_OUT_TIME = 'Art.266.5.2',
    ART_267_HIT_AND_HURRY = 'Art.267',
    ART_269_ACCUMULATOR = 'Art.269',
    ART_270_TOP_SCORE = 'Art.270',
    ART_271_TAKE_YOUR_OWN_LINE = 'Art.271',
    ART_273_TWO_ROUNDS = 'Art.273',
    ART_274_1_TWO_PHASES_NORMAL = 'Art.274.1',
    ART_274_1_5_1_TWO_PHASES_NORMAL = 'Art.274.1.5.1',
    ART_274_1_5_2_TWO_PHASES_NORMAL = 'Art.274.1.5.2',
    ART_274_1_5_3_TWO_PHASES_NORMAL = 'Art.274.1.5.3',
    ART_274_1_5_4_TWO_PHASES_NORMAL = 'Art.274.1.5.4',
    ART_274_1_5_5_TWO_PHASES_NORMAL = 'Art.274.1.5.5',
    ART_274_2_TWO_PHASES_SPECIAL = 'Art.274.2',
    ART_274_2_5_TWO_PHASES_SPECIAL = 'Art.274.2.5',
    ART_275_IN_GROUPS = 'Art.275',
    ART_276_WITH_WINNING_ROUND = 'Art.276',
    ART_277_DERBY = 'Art.277',
    ART_278_OVER_COMBINATIONS = 'Art.278'
}

type FeiRules = Article | string

export enum ObstacleMaterial {
    BAR = 'bar',
    BAR_RAISED = 'bar-raised',
    PLANK = 'plank',
    BRICKS = 'bricks'
}

export enum CombinationType {
    OPEN = 'open',
    CLOSED = 'closed',
    PARTIALLY_CLOSED = 'partially-closed'
}

export enum PdfOption {
    MAIN_DESIGN = 'main',
    MAIN_DESIGN_FIRST_ROUND_TRACK = 'main-first-round',
    MAIN_DESIGN_SECOND_ROUND_TRACK = 'main-second-round',
    EMPTY_ARENA = 'arena',
    MASTERPLAN = 'masterplan',
    MASTERPLAN_POSITION_LINES = 'masterplan-position-lines',
    OBSTACLES = 'obstacles',
    OBJECTS = 'objects'
}

function simplePropsCopy(
    obj: any, newObj: any,
    thisClass: any, parentClass: any | null,
    skipProps?: string[]) {

    let propsToCopy = Object.keys(new thisClass()) // all props including inherited

    if (parentClass != null) {
        // skip parent (inherited) class properties, they are copied in super()
        const parentObj = new parentClass()
        const parentProps = Object.keys(parentObj)
        propsToCopy = propsToCopy.filter(prop => parentProps.indexOf(prop) < 0);
    }

    if (skipProps && skipProps.length > 0) {
        propsToCopy = propsToCopy.filter(prop => skipProps.indexOf(prop) < 0);
    }

    for (const p of propsToCopy) {
        if (!obj.hasOwnProperty(p) || obj[p] == undefined) {
            //console.warn(`missing property ${p} in`, obj)
            continue
        }
        newObj[p] = obj[p]
    }
}

export class DesignColors {
    pathConnect: string = '#ff00ff'
    pathRegular: string = '#000000'
    pathRegular2: string = '#0000ff'
    tape: string = '#000000'
    pathWarn: string = '#ff0000'
    objectLabel: string = '#888888'
    objectLabel2: string = '#ff8888'
    objectLabelFill: string = '#ffffff'
    objectLabel2Fill: string = '#ffffff'
    midPoint: string = '#90ee91'
    obstacleArrows: string = '#ff8080'
    bankArrow: string = '#909090'
    fieldFrame: string = '#707dc8'  // this is our theme color
    rulerMarks: string = '#333333'
    grid: string = '#dddddd'
    paperLimits: string = '#aaaaaa'
    materials: string = '#000000'
    grayscale: boolean = false

    copy(colors: any) {
        simplePropsCopy(colors, this, DesignColors, null)
    }
}

export enum ShareMode {
    PUBLIC = 0,
    PRIVATE = 1,
}

export enum NodeFlags {
    NONE = 0,
    CLOSING = 1,
}

export const designDisplayOptionsFields = [
    "showHorseStepLen",
    "warnNotAlignedHorseStep",
    "showLinesOfSight",
    "displayEmptyTableItems",
    "crossOutUnused",
    "hideFieldBorder",
    "hideFieldRuler",
    "fieldRadius",
    "limitToField",
    "fontSize",
    "lineWidth",
    "pathWidth",
    "pathPattern1",
    "pathPattern2",
    "pathPattern3",
    "tapeWidth",
    "tapePattern",
    "paperMarginsCm",
    "labelFontSize",
    "tableFontSize",
    "distFontSize",
    "rulerFontSize",
    "showParkourDesignLabel",
    "distanceUnit",
    "sizeUnit",
    "colors.pathRegular",
    "colors.pathRegular2",
    "colors.tape",
    "colors.pathConnect",
    "colors.pathWarn",
    "colors.obstacleArrows",
    "colors.bankArrow",
    "colors.objectLabel",
    "colors.objectLabel2",
    "colors.objectLabelFill",
    "colors.objectLabel2Fill",
    "colors.midPoint",
    "colors.fieldFrame",
    "colors.rulerMarks",
    "colors.paperLimits",
    "colors.grid",
    "colors.materials",
    "colors.grayscale",
]

export const designPathPatternDistances: [number, number, number, number][] = [
    [0, 100000000, 0, 0],  // 0. brak
    [5, 0, 0, 0],          // 1. ciągła
    [2, 4, 0, 0],          // 2. kropki
    [2, 16, 0, 0],         // 3.
    [2, 32, 0, 0],         // 4.
    [10, 10, 0, 0],        // 5. kreski, path2 default
    [20, 20, 0, 0],        // 6.
    [40, 40, 0, 0],        // 7. path1 default
    [10, 10, 2, 10],       // 8. kreski i kropki
    [20, 20, 4, 20],       // 9.
    [40, 40, 6, 40],       // 10.
]


export class DesignFormParams implements Record<string, any> {
    shareMode: ShareMode = ShareMode.PRIVATE
    eventName: string = ''
    title: string = ''
    table: CompetitionTable = CompetitionTable.NONE
    nationalRules: string = ''
    feiRules: FeiRules = ''
    obstacleHeight: number = LimitsService.defaults.height?.default || 160
    obstacleHeights: ObstacleHeights = {}
    obstacleLength: number = LimitsService.defaults.length?.default || 350
    barSegments: number = 5
    speed: number = LimitsService.defaults.speeds?.outdoor.default || 350
    oxerWidth: number = LimitsService.defaults.width?.oxer.default || 160
    tripleBarWidth: number = LimitsService.defaults.width?.tripleBarre.default || 240
    ditchWidth: number = LimitsService.defaults.width?.ditch?.default || 200
    liverPoolWidth: number = LimitsService.defaults.width?.liverpool?.default || 200
    wallWidth: number = LimitsService.defaults.width?.wall?.default || 30
    timeLimit: number | null = 0 // null - automatic, 0 - no time limit
    compMinDist: number = 6
    compMaxDist: number = 12
    showHorseStepLen: boolean = true
    warnNotAlignedHorseStep: boolean = true
    strideMinLenM: number = 3.5
    strideMaxLenM: number = 4
    jumpBeforeLenM: number = 1.5
    landAfterLenM: number = 1.5
    parkourHeight: number = 40
    parkourWidth: number = 80
    classNo: ClassNo = ClassNo.NONE
    classes: ClassNo[] = []
    compLoc: CompetitionLocation = CompetitionLocation.OUTDOOR
    shortDescr: string = ''
    eventDate: Date | null = null
    fontSize: number = 100
    lineWidth: number = 1
    pathWidth: number = 1
    pathPattern1: number = 5
    pathPattern2: number = 8
    pathPattern3: number = 2
    tapeWidth: number = 2
    tapePattern: number = 2
    showLinesOfSight: boolean = true
    hideFieldBorder: boolean = false
    hideFieldRuler: boolean = false
    fieldRadius: number = 0
    colors: DesignColors = new DesignColors()
    tableLanguage: string = 'en'
    displayEmptyTableItems: boolean = false
    overrideDistance1: boolean = false
    distance1M: number = 0
    overrideDistance2: boolean = false
    distance2M: number = 0
    distanceCorrection: number = 0 // obsolete
    distanceCorrection2: number = 0  // obsolete
    distanceUnit: string = 'm-m'
    sizeUnit: string = 'm-m'
    crossOutUnused: boolean = true
    limitToField: boolean = true
    bannerPosition: BannerPosition = BannerPosition.TOP
    paperMarginsCm: number = 0.5
    labelFontSize: number = 100
    rulerFontSize: number = 100
    distFontSize: number = 100
    tableFontSize: number = 100
    showParkourDesignLabel: boolean = true
    editMode: EditMode = EditMode.CONNECTOR

    copy(design: any) {
        // correct speed
        if (design.speed instanceof String || typeof design.speed === 'string') {
            design.speed = parseInt(design.speed, 10)
        } else if (!(design.speed instanceof Number) && typeof design.speed !== 'number') {
            design.speed = this.speed
        }

        if (!(design.classNo instanceof String) && typeof (design.classNo) !== 'string' ||
            !Object.values(ClassNo).includes(design.classNo)) {
            design.classNo = ClassNo.NONE
        }

        if (design.classes && design.classes instanceof Array && design.classes.length === 0 && design.classNo !== ClassNo.NONE) {
            design.classes.push(design.classNo)
            design.classNo = ClassNo.NONE
        }

        if (design.fontSize) {
            if (!design.rulerFontSize) {
                design.rulerFontSize = design.fontSize
            }
            if (!design.distFontSize) {
                design.distFontSize = design.fontSize
            }
            if (!design.tableFontSize) {
                design.tableFontSize = design.fontSize
            }
        }

        simplePropsCopy(design, this, DesignFormParams, null, ['eventDate', 'colors'])

        // eventDate
        const eventDate: any = design.eventDate
        if (eventDate) {
            if (typeof eventDate === 'string') {
                this.eventDate = new Date(eventDate)
            } else if (eventDate.hasOwnProperty('seconds')) {
                this.eventDate = new Date(eventDate.seconds * 1000)
            } else if (eventDate instanceof Date) {
                this.eventDate = new Date(eventDate)
            }
        }

        // colors
        if (design.colors instanceof Object) {
            this.colors.copy(design.colors)
        }

        if (design.editMode && !Object.values(EditMode).includes(design.editMode)) {
            this.editMode = EditMode.CONNECTOR
        }

        // per-class heights
        this.obstacleHeights = {}
        for (let c of this.classes) {
            if (design.obstacleHeights && design.obstacleHeights.hasOwnProperty(c)) {
                this.obstacleHeights[c] = design.obstacleHeights[c]
                this.obstacleHeight = design.obstacleHeights[c]
            }
        }
        this.eventName = this.eventName.trim()
        this.title = this.title.trim()
    }

    loadDefaultParams(limitsService: LimitsService, displayOptionsDefaults: DesignDisplayOptions | undefined, classes: ClassNo[], loc: CompetitionLocation) {
        // obstacle defaults
        const l = limitsService.getManyLimits(classes || []) || LimitsService.defaults
        this.obstacleHeight = l.height?.default || 160
        this.obstacleLength = l.length?.default || 350
        this.speed = l.speeds?.[loc || this.compLoc].default || 350
        this.oxerWidth = l.width?.oxer.default || 160
        this.tripleBarWidth = l.width?.tripleBarre.default || 240
        this.ditchWidth = l.width?.ditch?.default || 200
        this.liverPoolWidth = l.width?.liverpool?.default || 200
        this.wallWidth = l.width?.wall?.default || 30
        this.timeLimit = 0

        this.obstacleHeights = limitsService.getHeightDefaults(classes)

        // display options defaults
        if (displayOptionsDefaults) {
            for (let field of designDisplayOptionsFields) {
                let val = displayOptionsDefaults[field]
                if (val !== undefined) {
                    if (field.includes('.')) {
                        const parts = field.split('.')
                        const o = (this as any)
                        o[parts[0]][parts[1]] = val
                    } else {
                        const o = (this as any)
                        o[field] = val
                    }
                }
            }
        }
    }
}

export class DesignMidPoint {
    x: number = 0
    y: number = 0
    handleFactor: number = 0

    copy(mp: any) {
        simplePropsCopy(mp, this, DesignMidPoint, null)
    }
}

export class DesignNode {
    uuid: string = ''
    midPoints: DesignMidPoint[] = []
    flags: NodeFlags = NodeFlags.NONE

    copy(node: any) {
        simplePropsCopy(node, this, DesignNode, null, ['midPoints'])

        if (node.midPoints instanceof Array) {
            for (const mp of node.midPoints) {
                const newMp = new DesignMidPoint()
                newMp.copy(mp)
                this.midPoints.push(newMp)
            }
        }
    }
}

export class DesignBaseObject {
    uuid: string = ''
    kind: string = ''
    x: number = 0
    y: number = 0
    angle: number = 0
    layer: number = 0

    copy(obj: any) {
        simplePropsCopy(obj, this, DesignBaseObject, null)
    }
}
export class DesignPoint {
    x: number = 0
    y: number = 0

    copy(obj: any) {
        simplePropsCopy(obj, this, DesignPoint, null)
    }
}
export class DesignDrawingSegment {
    x: number = 0
    y: number = 0
    handleIn: DesignPoint | null = null
    handleOut: DesignPoint | null = null

    copy(obj: any) {
        simplePropsCopy(obj, this, DesignDrawingSegment, null)
    }
}
export class DesignDrawingObject extends DesignBaseObject {
    fillColor: string = '#aaee22'
    fillEnable: boolean = false
    strokeWidth: number = 10
    strokeColor: string = '#000'
    closed: boolean = false
    locked: boolean = false
    segments: DesignDrawingSegment[] = []

    copy(obj: any) {
        super.copy(obj)
        simplePropsCopy(obj, this, DesignDrawingObject, DesignBaseObject)
    }
}
export class DesignUserImageObject extends DesignBaseObject {
    imageData: string | null = null
    imageId: string = getRandomId()
    imageFormat: string = 'media/png'
    imageSize: number[] | null = null
    border: boolean = false
    locked: boolean = false
    specialId: string = ''

    copy(obj: any) {
        super.copy(obj)
        simplePropsCopy(obj, this, DesignUserImageObject, DesignBaseObject)
    }
}
export class DesignSvgImageObject extends DesignBaseObject {
    imageSize: number[] | null = null

    copy(obj: any) {
        super.copy(obj)
        simplePropsCopy(obj, this, DesignSvgImageObject, DesignBaseObject)
    }
}
export class DesignTextBoxObject extends DesignBaseObject {
    specialId: string = ''
    content: string = ''
    border: boolean = false
    txtScale: number = 1
    strokeColor: string = '#000000'
    fillColor: string = '#aaaaaa'
    borderColor: string = '#000000'
    fillEnable: boolean = false
    justification: string = 'left'
    fontFamily: string = 'Courier New'

    copy(obj: any) {
        super.copy(obj)
        simplePropsCopy(obj, this, DesignTextBoxObject, DesignBaseObject)
    }
}
export class DesignPathObject extends DesignSvgImageObject {
    directionRound1: number = 0
    directionRound2: number = 0
    name: string = ''
    userArrowDirection: Direction = Direction.both
    joker: boolean = false
    jokerNumber: number = 0

    copy(obj: any) {
        super.copy(obj)
        simplePropsCopy(obj, this, DesignPathObject, DesignSvgImageObject)
    }
}
export class DesignTerminalObject extends DesignPathObject {
    cellsDistance: number = 400
    arrowDistance: number = 200

    copy(obj: any) {
        super.copy(obj)
        simplePropsCopy(obj, this, DesignTerminalObject, DesignPathObject)
    }
}
export class DesignBar {
    material: ObstacleMaterial = ObstacleMaterial.BAR
    label: string = ''
    copy(obj: any) {
        simplePropsCopy(obj, this, DesignBar, null)
    }
}
export class DesignMaterials {
    topRow: DesignBar[] = []
    rows: DesignBar[] = []
    visible: boolean = true

    static readonly defaultRows: number = 3
    static readonly maxRows: number = 5
    static readonly maxBars: number = 3

    copy(obj: any) {
        if (typeof obj.visible === "boolean") {
            this.visible = obj.visible
        }
        if (obj.topRow instanceof Array) {
            for (let i = 0; i < DesignMaterials.maxBars && i < obj.topRow.length; i++) {
                const r = obj.topRow[i]
                const row = new DesignBar()
                row.copy(r)
                this.topRow.push(row)
            }
        }
        if (obj.rows instanceof Array) {
            for (let i = 0; i < DesignMaterials.maxRows - 1 && i < obj.rows.length; i++) {
                const r = obj.rows[i]
                const row = new DesignBar()
                row.copy(r)
                this.rows.push(row)
            }
        }
    }
}
export class DesignObstacleObject extends DesignPathObject {
    height: number = 0
    width: number = 0
    length: number = 0
    materials: DesignMaterials | null = null
    score: number = 0
    manualLabels: string[] = ['', '', '', '']

    copy(obj: any) {
        super.copy(obj)
        simplePropsCopy(obj, this, DesignObstacleObject, DesignPathObject, ['materials'])
        if (obj.materials instanceof Object) {
            this.materials = new DesignMaterials()
            this.materials.copy(obj.materials)
        }
        if (Array.isArray(obj.manualLabels) && obj.manualLabels.length === 4) {
            for (let i = 0; i <= 3; i++) {
                const l = obj.manualLabels[i]
                if (typeof l === 'string') {
                    this.manualLabels[i] = l
                } else {
                    this.manualLabels[i] = ''
                }
            }
        }
    }
}
export class DesignDrawnObstacleDecorations {
    grass: boolean = false
    water: boolean = false
    singlePond: boolean = false
    pondLeft: boolean = false
    pondRight: boolean = false
    doublePond: boolean = false

    copy(obj: any) {
        simplePropsCopy(obj, this, DesignDrawnObstacleDecorations, null)
    }
}
export class DesignBankObstacleObject extends DesignObstacleObject {
    arrowLength: number = 500

    copy(obj: any) {
        simplePropsCopy(obj, this, DesignBankObstacleObject, null)
    }
}
export class DesignDrawnObstacleObject extends DesignObstacleObject {
    decorations: DesignDrawnObstacleDecorations = new DesignDrawnObstacleDecorations()

    copy(obj: any) {
        super.copy(obj)
        simplePropsCopy(obj, this, DesignDrawnObstacleObject, DesignObstacleObject, ['decorations'])

        if (obj.decorations instanceof Object) {
            this.decorations.copy(obj.decorations)
        }
    }
}
class DesignBannerVisibility {
    title: boolean = true
    subtitle: boolean = true
    table: boolean = true
    combinations: boolean = true

    copy(obj: any) {
        simplePropsCopy(obj, this, DesignBannerVisibility, null)
    }
}
export class DesignBannerObject extends DesignBaseObject {
    visibility: DesignBannerVisibility = new DesignBannerVisibility()
    orientation: number = 1
    scale: number = 1

    copy(obj: any) {
        super.copy(obj)
        simplePropsCopy(obj, this, DesignBannerObject, DesignBaseObject, ['visibility'])

        if (obj.visibility instanceof Object) {
            this.visibility.copy(obj.visibility)
        }
    }
}

const KindToClassMap: { [id: string]: any } = {
    'gate': DesignDrawnObstacleObject,
    'vertical-vector': DesignDrawnObstacleObject,
    'oxer-vector': DesignDrawnObstacleObject,
    'triple-barre-vector': DesignDrawnObstacleObject,
    'ditch': DesignDrawnObstacleObject,
    'wall': DesignDrawnObstacleObject,
    'corner-oxer': DesignDrawnObstacleObject,
    'corner-triple-barre': DesignDrawnObstacleObject,
    'start': DesignTerminalObject,
    'finish': DesignTerminalObject,
    'finish-start': DesignPathObject,
    'grass-1': DesignSvgImageObject,
    'grass-2': DesignSvgImageObject,
    'service-hut': DesignSvgImageObject,
    'in-out': DesignSvgImageObject,
    'text-box': DesignTextBoxObject,
    'user-image': DesignUserImageObject,
    'drawing': DesignDrawingObject,
    'table-banner': DesignBannerObject,
    'car': DesignSvgImageObject,
    'clock': DesignSvgImageObject,
    'bank': DesignBankObstacleObject,
}

export function getRandomId() {
    const localId = Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2)
    return localId
}

export class DesignObjectGroup {
    uuids: SUUID[] = []
    combinationType: CombinationType = CombinationType.OPEN
    copy(design: any) {
        simplePropsCopy(design, this, DesignObjectGroup, null, ['uuids'])
        if (design.uuids instanceof Array) {
            for (const uuid of design.uuids) {
                this.uuids.push(uuid)
            }
        }
    }
}

export class DesignSchema extends DesignFormParams {
    author: string | null = null
    origAuthor: string | null = null
    authorName: string | null = null
    formatVersion: number = 1
    localId: string = getRandomId()
    remoteId: string | null = null
    createdAt: string = (new Date()).toISOString()
    updatedAt: string = (new Date()).toISOString()
    deletedAt: string | null = null
    saved: boolean = false
    route: DesignNode[] = []
    objects: DesignBaseObject[] = []
    groups: DesignObjectGroup[] = []
    userIndex: number = 0

    copy(design: any) {
        //console.info('loading design', design.localId, design.remoteId, design.title, design.author)

        super.copy(design)
        simplePropsCopy(design, this, DesignSchema, DesignFormParams, ['route', 'objects'])

        // objects
        const objectsMap: any = {}
        if (design.objects instanceof Array) {
            for (const obj of design.objects) {
                let newObj: DesignBaseObject
                try {
                    newObj = checkAndFixObject(obj)
                } catch (err) {
                    console.error('some problem with an object', err, obj)
                    continue
                }
                this.objects.push(newObj)
                objectsMap[newObj.uuid] = true
            }
        }

        // route
        if (design.route instanceof Array) {
            for (const node of design.route) {
                const newNode = new DesignNode()
                newNode.copy(node)

                if (!objectsMap[newNode.uuid]) {
                    continue
                }

                this.route.push(newNode)
            }
        }

        // groups
        if (design.groups instanceof Array) {
            for (const group of design.groups) {
                let skip = false
                const newGroup = new DesignObjectGroup()
                newGroup.copy(group)
                for (const uuid of newGroup.uuids) {
                    if (!objectsMap[uuid]) {
                        skip = true
                        break
                    }
                    for (const g of this.groups) {
                        if (g.uuids.includes(uuid)) {
                            skip = true
                            break
                        }
                    }
                    if (skip) {
                        break
                    }
                }
                if (!skip && newGroup.uuids.length > 0) {
                    this.groups.push(newGroup)
                }
            }
        }
    }
}

export type DesignSchemaWithPreview = DesignSchema & {
    pngUrl?: string | null,
    png?: string,
}

function checkAndFixObject(obj: any): DesignBaseObject {
    if (obj.kind === 'grass-3') {
        obj.kind = 'grass-1'
    }
    const kind = obj.kind
    if (kind === undefined || KindToClassMap[kind] === undefined) {
        console.error(`missing or unrecognized kind ${kind} in`, obj)
        throw Error(`missing or unrecognized kind ${kind}`)
    }
    const cls = KindToClassMap[kind]
    const newObj = new cls()
    newObj.copy(obj)
    return newObj
}
