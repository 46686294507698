import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core'
import '@angular/localize/init'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'

import { Analytics, logEvent } from '@angular/fire/analytics'
import { User } from '@angular/fire/auth'
import { AngularDeviceInformationService } from 'angular-device-information'
import paper from 'paper'
import { Key } from 'paper/dist/paper-core'
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import { ContentSvg } from 'pdfmake/interfaces'
import { Subscription, TimeoutError, fromEvent, map, merge, of, timeout } from 'rxjs'
pdfMake.vfs = pdfFonts.pdfMake.vfs

import { MenuItem, MessageService, TreeNode } from 'primeng/api'
import { Table, TableFilterEvent } from 'primeng/table'
import { TabMenu } from 'primeng/tabmenu'

import { BannerPosition, DesignSchema, Direction, EditMode, NodeFlags, PdfOption } from '../design.schema'
import { ExportMode, ParkourCanvas, PngImage } from '../parkour-canvas/parkour-canvas'
import { ParkourConfig } from '../parkour.config'
import { AuthService } from '../services/auth.service'
import { BrevoService } from '../services/brevo.service'
import { LimitsService } from "../services/limits.service"
import { StorageService } from '../services/storage.service'
import { PdfOptions, UserProfile, UserService } from '../services/user.service'
import { UserSubscriptionService, UserFeatures } from '../services/user-subscription.service'
import { getBuildVersion, getWPWebLink, showErrorBox, showInfoBox, showSuccessBox } from '../utils'
import { ParkourObjectGroup } from './detail.group'
import { canBeInPath } from './detail.path'
import { PathMidPoint } from './detail.path.midpoint'
import { ObstaclePathNode } from './detail.path.node'
import { Selectable, Selection, isEditable } from './detail.selection'
import { SelectionActivity } from './detail.selectors'
import { TapeMeasure } from './detail.tape.measure'
import { UiCommands } from "./detail.ui.commands"
import { UiButton, UiCommandId, UiContextMenuItem, UiGroupId } from './detail.ui.commands.defs'
import { ToggleMenuItem } from './detail.ui.toggle'
import { Undo } from './detail.undo'
import { ParkourValidator, Warning } from './detail.validator'
import { ParkourDialog, ParkourDialogId } from './dialogs/detail.dialog.interface'
import { ParkourCompetitionParamsComponent } from './dialogs/parkour-competition-params/parkour-competition-params.component'
import { ParkourDebugOptionsComponent } from './dialogs/parkour-debug-options/parkour-debug-options.component'
import { ParkourDesignStatsComponent } from './dialogs/parkour-design-stats/parkour-design-stats.component'
import { ParkourDisplayOptionsComponent } from './dialogs/parkour-display-options/parkour-display-options.component'
import { ParkourFieldSizeComponent } from './dialogs/parkour-field-size/parkour-field-size.component'
import { ParkourMaterialsComponent } from './dialogs/parkour-materials/parkour-materials.component'
import { ParkourValidationComponent } from './dialogs/parkour-validation/parkour-validation.component'
import { ParkourObjectFactory, ParkourObjectTemplate } from './parkour-object-factory/parkour-object-factory.service'
import { ParkourObjectPanelComponent } from './parkour-object-panel/parkour-object-panel.component'
import { Drawing } from './parkour-objects/drawing'
import { ObjectGenerator } from './parkour-objects/generator'
import { Landscape } from './parkour-objects/landscape'
import { Obstacle } from './parkour-objects/obstacle'
import { ObstacleWithBars } from './parkour-objects/obstacle-with-bars'
import { ParkourObject, ParkourObjectKind } from './parkour-objects/parkour-object'
import { PathObject } from './parkour-objects/path-object'
import { UserImage } from './parkour-objects/user-image'
import { Sorter, SortMode } from '../sorter'
import { LengthPipe } from '../pipes'

type CompetitionEvent = {
    eventName: string,
    eventDate?: string
}

type PdfOptionsData = {
    id: PdfOption,
    name: string,
    description: string,
    checked: boolean,
    disabled?: boolean,
    tooltip?: string,
    page?: string
}

enum TabIds {
    OBSTACLES = 'obstacles',
    OBJECTS = 'objects',
    GROUPS = 'groups',
    DESIGNS = 'designs',
    IMAGES = 'images',
}

enum Cursor {
    DEFAULT = 'auto',
    PAN_FIELD = 'move',
    TAPE_MEASURE = 'crosshair',
}

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy, AfterViewInit {
    public subs: Subscription = new Subscription()
    user: User | null = null

    designMenuItems: MenuItem[] = []
    viewMenuItems: MenuItem[] = []
    editMenuItems: MenuItem[] = []
    transformationMenuItems: MenuItem[] = []
    helpMenuItems: MenuItem[] = []
    tabItems: MenuItem[] = []
    tabIds: { [id: string]: number } = {}
    activeTab: MenuItem
    inProgress: boolean = false
    pointSelection: Selectable[] = []
    selectedIndex: number = 0

    selection: Selection = new Selection()
    undo: Undo
    mouseOverPathNode?: ObstaclePathNode

    newDesign: boolean = false
    designData: DesignSchema | null = null
    allMyDesigns: DesignSchema[] = []
    filteringEvent: string
    readonly noEventsFilter = $localize`== wszystkie ==`
    designesTableInitFilter: any = {}
    myEvents: CompetitionEvent[] = []
    myEventsWithAll: CompetitionEvent[] = []
    performingUndo: boolean = false

    bannersVisible: boolean = true
    selectedObjectsInList: ParkourObject[] = []
    selectedObstaclesInList: ObstaclePathNode[] = []
    selectedGroupsInList: ParkourObjectGroup[] = []
    selectedDesign?: DesignSchema
    filteredDesigns?: DesignSchema[]

    uiCommands: UiCommands = new UiCommands(this, this.locale)
    obstaclesListCollapsed: boolean = false

    @ViewChild('canvasO') canvas?: ParkourCanvas
    @ViewChild('canvas2') canvasElement!: ElementRef<HTMLElement>

    @ViewChild('toolbar') toolbarElement: ElementRef<HTMLElement> | undefined
    @ViewChild('objectTabMenu') objectTabMenuEl: TabMenu | undefined
    @ViewChild('objectPanel') objectPanelEl: ParkourObjectPanelComponent | undefined
    @ViewChild('obstaclesTable') obstaclesTable: Table | undefined
    @ViewChild('objectsTable') objectsTable: Table | undefined
    @ViewChild('designsTable') designsTable: Table | undefined
    @ViewChild('paramsDialog') paramsDialog: ParkourCompetitionParamsComponent | undefined
    @ViewChild('displayOptionsDialog') displayOptionsDialog: ParkourDisplayOptionsComponent | undefined
    @ViewChild('fieldSizeDialog') fieldSizeDialog: ParkourFieldSizeComponent | undefined
    @ViewChild('debugOptionsDialog') debugOptionsDialog: ParkourDebugOptionsComponent | undefined
    @ViewChild('designStatsDialog') designStatsDialog: ParkourDesignStatsComponent | undefined
    @ViewChild('materialsDialog') materialsDialog: ParkourMaterialsComponent | undefined
    @ViewChild('validationDialog') validationDialog: ParkourValidationComponent | undefined

    rowHeight = 40

    tool?: paper.Tool

    mouseDownPoint: any
    panFieldActive?: boolean
    panFieldPrevCursor?: string
    mousePoint: any

    startTouches: any
    startZoom: number = 0
    connectMode: boolean = false
    get tapeMeasureMode(): boolean {
        return this.uiCommands.getToggler(UiCommandId.TAPE_MEASURE)?.onOffState || false
    }
    readonly tapeMeasure: TapeMeasure = new TapeMeasure(this)

    errorMsg: string = ''
    interactiveMediaFeatures: string = ''
    parkourDialogs: { [id: string]: ParkourDialog } = {}

    cfg: ParkourConfig
    userProfile: UserProfile = UserService.userProfileDefaults
    loadingData: boolean = false
    firstDataLoading: boolean = true
    shiftOnMouseDown: boolean = false
    rmbOnMouseDown: boolean = false
    draggingObject: any
    draggingOffset!: number[]
    dropDownClick: boolean = false
    blockUI: boolean = false
    cursor: string = Cursor.DEFAULT

    // help features
    keyShortcutsDlgVisible: boolean = false
    keyShortcutsEdit: boolean = false
    validator: ParkourValidator = new ParkourValidator(this)
    validationCallback?: () => void
    designPreviewVisible: boolean = false
    designPreviewUrl: string = ''
    linkWhatsNew: string
    linkGuides: string

    features: UserFeatures

    public version: string
    public newVersionText: string
    private lastVersion: string | undefined = undefined
    private static _lengthPipe: LengthPipe = new LengthPipe()

    printDlgVisible: boolean = false
    pdfOperation: string = ''

    pdfOptions: TreeNode<PdfOptionsData>[] = [
        {
            data: {
                id: PdfOption.MAIN_DESIGN,
                name: $localize`Projekt główny`,
                description: $localize`Plan parkuru dla zawodników`,
                checked: true,
                page: 'main'
            },
            children: [
                {
                    data: {
                        id: PdfOption.MAIN_DESIGN_FIRST_ROUND_TRACK,
                        name: $localize`Trasa pierwszej rundy`,
                        description: $localize`Kształt trasy przebiegu podstawowego`,
                        checked: true,
                    }
                },
                {
                    data: {
                        id: PdfOption.MAIN_DESIGN_SECOND_ROUND_TRACK,
                        name: $localize`Trasa drugiej rundy`,
                        description: $localize`Kształt trasy drugiej fazy lub rozgrywki`,
                        checked: false,
                    }
                }
            ]
        }, {
            data: {
                id: PdfOption.EMPTY_ARENA,
                name: $localize`Plac`,
                description: $localize`Pusty plac bez trasy i przeszkód, z obiektami stałymi`,
                checked: false,
                page: 'blank'
            }
        }, {
            data: {
                id: PdfOption.MASTERPLAN,
                name: $localize`Masterplan`,
                description: $localize`Plan parkuru dla gospodarzy i obsługi toru`,
                checked: false,
                page: 'masterplan'
            },
            children: [
                {
                    data: {
                        id: PdfOption.MASTERPLAN_POSITION_LINES,
                        name: $localize`Linie końców przeszkód`,
                        description: $localize `Linie odległości końców przeszkód od granic placu`,
                        checked: false,
                    }
                }
            ],
        }, {
            data: {
                id: PdfOption.OBSTACLES,
                name: $localize`Lista przeszkód`,
                description: $localize`Tabela z parametrami wszystkich przeszkód`,
                checked: false,
                page: 'obstacles'
            }
        }, {
            data: {
                id: PdfOption.OBJECTS,
                name: $localize`Lista obiektów`,
                description: $localize`Tabela z parametrami wszystkich obiektów stałych`,
                checked: false,
                page: 'objects'
            }
        }
    ]

    private saved = 0
    private traceNodeIdx?: number
    compSorter: Sorter<DesignSchema>
    customSortMode: SortMode<DesignSchema>

    groupedToggleItems: ToggleMenuItem[][] = [
        //        [this.toggleRoute1, this.toggleLabels1, this.toggleDistances1],
        //        [this.toggleRoute2, this.toggleLabels2, this.toggleDistances2],
        //        [this.toggleRoute3, this.toggleDistances3]
    ]
    individualToggleItems: ToggleMenuItem[] = []
    //    [this.togglePageLimits, this.toggleGrid, this.toggleControlPoints]

    largeToggleButtons: (ToggleMenuItem | undefined)[] = []
    largeButtons: (UiButton | undefined)[] = []
    objectButtons: (UiContextMenuItem | undefined)[] = []

    _getMenuLabel(label: string, shortcut?: UiCommandId): string {
        let t = '<div>' + label + '</div>'
        if (shortcut) {
            let s = this.uiCommands.shortcuts.getShortcutLabelOrUndefined(shortcut)
            if (s) {
                t += '<div>&nbsp;&nbsp;&nbsp;&nbsp;</div><div class="shortcut">' + s + '</div>'
            }
        }
        return t
    }

    buildMenuItems() {
        const ui = this.uiCommands
        ui.rebuildMenus()

        this.designMenuItems = ui.getMenu(UiGroupId.PROJECT)?.items || []
        this.viewMenuItems = ui.getMenu(UiGroupId.VIEW)?.items || []
        this.editMenuItems = ui.getMenu(UiGroupId.EDIT)?.items || []
        this.transformationMenuItems = ui.getMenu(UiGroupId.TRANSFORM)?.items || []
        this.helpMenuItems = ui.getMenu(UiGroupId.HELP)?.items || []

        this.objectButtons = [
            ui.getContextMenuItem(UiCommandId.DELETE_ROUTE_BACKWARD),
            ui.getContextMenuItem(UiCommandId.DELETE_ROUTE_FORWARD),
            ui.getContextMenuItem(UiCommandId.SPLIT_PATH_AFTER),
            ui.getContextMenuItem(UiCommandId.DETACH_OBJECT),
        ]

        this.largeButtons = [
            ui.getButton(UiCommandId.CLEAR_PATH),
            ui.getButton(UiCommandId.CLEAR_FIELD),
            ui.getButton(UiCommandId.CLEAR_ALL_MANUAL_LABELS),
            ui.getButton(UiCommandId.PRINT),
            ui.getButton(UiCommandId.UNDO),
        ]

        this.largeToggleButtons = [
            ui.getToggler(UiCommandId.TAPE_MEASURE)
        ]

        const t = this.uiCommands.getToggler(UiCommandId.CHANGE_PATH_EDIT_MODE)
        if (t) {
            t.onOffState = (this.cfg.params.editMode === EditMode.DRAWING)
        }
    }

    get contextMenuItems(): UiContextMenuItem[] | undefined {
        return this.uiCommands.getContextMenuItems(this.selection.selectedFlatItems)
    }

    resolutionWarningDlgVisible = false

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public msgSvc: MessageService,
        public deviceInfo: AngularDeviceInformationService,
        private auth: AuthService,
        public store: StorageService,
        private brevoService: BrevoService,
        public sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(LOCALE_ID) public locale: string,
        public analytics: Analytics,
        public userService: UserService,
        public userSubscriptionService: UserSubscriptionService,
        public limitsService: LimitsService,
        public objectFactory: ParkourObjectFactory,
    ) {
        this.version = getBuildVersion()
        this.newVersionText = $localize`Zainstalowano nową wersję aplikacji` + ' (' + this.version + ')'
        this.linkWhatsNew = getWPWebLink('new-application-version-' + this.version, this.locale)
        this.linkGuides = getWPWebLink('wiki/guides', this.locale)
        const interactiveMediaQueries = [
            '(pointer: coarse)', '(pointer: fine)', '(pointer: none)',
            '(hover: hover)', '(hover: none)',
            '(any-hover: hover)', '(any-hover: none)']
        this.interactiveMediaFeatures = ''
        for (let q of interactiveMediaQueries) {
            if (window.matchMedia(q).matches) {
                this.interactiveMediaFeatures += ' ' + q
                //console.info(q, 'supported')
            } else {
                //console.info(q, 'NOT supported')
            }
        }
        this.cfg = new ParkourConfig(this.limitsService, {})
        this.features = userSubscriptionService.getStartingFeatures()

        this.buildMenuItems()

        this.tabItems = [
            { id: TabIds.OBSTACLES, title: $localize`Trasa`, icon: 'pi pi-fw pi-map' },
            { id: TabIds.OBJECTS, title: $localize`Obiekty`, icon: 'fa-solid fa-bullseye' },
            { id: TabIds.GROUPS, title: $localize`Grupy`, icon: 'fa-regular fa-object-group' },
            { id: TabIds.DESIGNS, title: $localize`Projekty`, icon: 'pi pi-fw pi-file' },
            { id: TabIds.IMAGES, title: $localize`Obrazki`, icon: 'pi pi-fw pi-image' },
        ]
        this.tabItems.forEach((item, idx) => {
            if (item.id) {
                this.tabIds[item.id] = idx
            }
        })
        this.activeTab = this.tabItems[0]
        this.filteringEvent = this.noEventsFilter

        this.undo = new Undo(20)

        this.compSorter = new Sorter(
            () => this.designsTable?.filteredValue || this.allMyDesigns, [
            new SortMode($localize`Sortuj projekty według nazwy`,
                'fa-solid fa-arrow-down-a-z',
                'fa-solid fa-arrow-down-z-a',
                true,
                (a: DesignSchema, b: DesignSchema, dirAsc: boolean) => {
                    // when no event or part of one event that is filtered, sort by competition title
                    // when there are events, first sort by event name, then by competition title
                    let c
                    if (this.designsTable?.filteredValue || !a.eventName && !b.eventName) {
                        c = a.title.localeCompare(b.title)
                    } else {
                        if (a.eventName && b.eventName) {
                            c = a.eventName.localeCompare(b.eventName)
                            if (c === 0) {
                                c = a.title.localeCompare(b.title)
                            }
                        } else if (a.eventName) {
                            c = -1
                        } else {
                            c = 1
                        }
                    }
                    return dirAsc ? c : -c
                },
                undefined,
                true
            ),
            new SortMode($localize`Sortuj projekty według daty konkursu`,
                'fa-solid fa-arrow-down-1-9',
                'fa-solid fa-arrow-down-9-1',
                false,
                (a: DesignSchema, b: DesignSchema, dirAsc: boolean) => Sorter.sortDates(a.eventDate, b.eventDate, dirAsc)
            ),
            this.customSortMode = new SortMode($localize`Sortuj projekty według kolejności zdefiniowanej przez użytkownika`,
                'fa-solid fa-arrow-down-short-wide',
                'fa-solid fa-arrow-down-short-wide',
                true,
                (a: DesignSchema, b: DesignSchema) => {
                    if (a.eventName && b.eventName) {
                        if (a.eventName === b.eventName) {
                            if (!a.userIndex && !b.userIndex) {
                                return b.updatedAt.localeCompare(a.updatedAt)
                            } else if (!a.userIndex) {
                                return -1
                            } else if (!b.userIndex) {
                                return 1
                            }
                            return a.userIndex - b.userIndex
                        } else {
                            return a.eventName.localeCompare(b.eventName)
                        }
                    } else if (a.eventName) {
                        return -1
                    } else if (b.eventName) {
                        return 1
                    } else {
                        return a.title.localeCompare(b.title)
                    }
                },
                () => (this.filteringEvent === this.noEventsFilter),
                false,
                { custom: true }
            )
        ])

        if (window.screen.width < 1366) {
            this.resolutionWarningDlgVisible = true
        }
    }

    ngOnInit(): void {
        this.subs.add(
            this.route.queryParamMap.subscribe({
                next: (params) => {
                    const localId = params.get('id') || ''
                    const author = params.get('author') || ''
                    this.newDesign = params.get('newDesign') === 'true'
                    const projectsTab: boolean = params.get('projectsTab') === 'true'

                    if (this.cfg.params.localId != localId || this.cfg.params.author != author) {
                        this.cfg.params.localId = localId
                        this.cfg.params.author = author
                        if (this.user) {
                            this.loadData(false, projectsTab)
                        }
                    }
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie informacji o parametrach z URL`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )

        if (!this.features.workOffline) {
            this.blockUI = !navigator.onLine
        } else {
            this.blockUI = false
        }
        this.subs.add(
            merge(
                of(null),
                fromEvent(window, 'online'),
                fromEvent(window, 'offline')
            ).pipe(map(() => navigator.onLine)).subscribe(status => {
                console.log('network status', status);
                if (!this.features.workOffline || status) {
                    this.blockUI = !status
                }
            })
        )

        const appVersion = 'appVersion'
        this.subs.add(
            this.store.getGlobalValue(appVersion).subscribe({
                next: (lastVersion) => {
                    // try to read last version from local store
                    if (lastVersion && lastVersion.name === appVersion &&
                        lastVersion.value && typeof lastVersion.value === 'string') {
                        this.lastVersion = lastVersion.value
                    }
                    // save current version as last version if this is different to the
                    // last version, including undefined last version
                    if (this.version && this.lastVersion !== this.version) {
                        this.store.setGlobalValue(appVersion, this.version).subscribe({
                            error: err => {
                                console.error('error setting app version', err)
                            }
                        })
                    }
                    if (!this.lastVersion) {
                        this.msgSvc.add({
                            key: 'new-user',
                            severity: 'info',
                            sticky: true,
                            closable: true,
                        })
                    } else if (this.lastVersion !== this.version) {
                        this.msgSvc.add({
                            key: 'whats-new',
                            severity: 'info',
                            sticky: true,
                            closable: true,
                        })
                    }
                }
            })
        )
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
        this.selectionClear()
        this.brevoService.show()
        this.tapeMeasure.destroy()
    }

    showSupportChat() {
        this.brevoService.showAndOpen()
    }

    goToSubscriptionPlans() {
        this.router.navigate(['/user-plan'])
    }

    actionLog(action: string, source: string) {
        logEvent(this.analytics, 'a_' + action, { action_source: source })
    }

    ngAfterViewInit() {
        this.brevoService.hide()

        if (!this.canvas) {
            console.error('no canvas element, cannot initialize')
            return
        }
        this.tool = new paper.Tool()
        paper.tool = this.tool
        this.tool.onMouseUp = this.onMouseUp.bind(this)
        this.tool.onMouseDown = this.onMouseDown.bind(this)
        this.tool.onMouseDrag = this.onMouseDrag.bind(this)
        if (this.canvas.paper) {
            this.canvas.paper.view.onMouseMove = this.onMouseMove.bind(this)
            this.canvas.paper.view.onMouseLeave = this.onMouseLeave.bind(this)

            var e = this.canvas.paper.view.element
            const w = this.onMouseWheel.bind(this)
            if ('onwheel' in document) {
                e.addEventListener('wheel', w)
            } else if ('onmousewheel' in document) {
                e.addEventListener('mousewheel', w)
            } else {
                e.addEventListener('MozMousePixelScroll', w)
            }
            e.addEventListener('touchstart', this.onTouchStart.bind(this))
            e.addEventListener('touchend', this.onTouchEnd.bind(this))
            e.addEventListener('touchmove', this.onTouchMove.bind(this))
        }
        this.canvas.changeArenaSize()
        this.canvas.changeViewSize()

        const toAddSvg: ParkourObjectTemplate[] = this.objectFactory.getObstacleDefinitions().concat(this.objectFactory.getObstacleTemplates()).filter(o => !o.url)
        for (let i of toAddSvg) {
            let o = this.objectFactory.getObject({
                cfg: this.cfg,
                canvas: this.canvas,
                store: this.store,
                subs: this.subs,
                object: {
                    kind: i.kind,
                    x: 0, y: 0, angle: 0,
                    decorations: i.decorations
                },
                view: this,
            })
            if (o instanceof ObstacleWithBars) {
                o.setArrowDirection(Direction.forward)
                const scale = Math.min(0.1, 40 / Math.max(o.drawing.bounds.size.width, o.drawing.bounds.size.height))
                o.drawing.scale(scale, o.getPosition())
                let svg = o.toSvg()
                if (svg) {
                    const html = this.sanitizer.bypassSecurityTrustHtml(svg)
                    i.svg = html
                }
            }
            if (o instanceof ParkourObject) {
                o.destroy()
            }
        }

        this.startInProgress()
        this.subs.add(
            this.auth.user.subscribe({
                next: (aUser: User | null) => {
                    if (!aUser) {
                        this.user = aUser
                        this.stopInProgress()
                        return
                    }
                    if (!this.user) {
                        this.user = aUser
                        this.loadData(true)
                    } else {
                        this.user = aUser
                    }

                    this.userService.getUserProfile().subscribe({
                        next: (userProfile: UserProfile | null | undefined) => {
                            if (!userProfile) {
                                return
                            }
                            let changed: boolean = false
                            this.userProfile = structuredClone(userProfile)
                            if (this.userProfile.undoLimit !== undefined) {
                                this.undo.setLimit(this.userProfile.undoLimit)
                            }
                            if (this.userProfile.shortcuts) {
                                changed = this.uiCommands.shortcuts.fromJson(this.userProfile.shortcuts, this.userProfile.version) || changed
                            }
                            if (this.canvas && this.canvas.obstaclePath.route.length > 1) {
                                this.attentionForStartSet(false)
                            }
                            this._loadCompSorterMode()
                            changed = (this.userProfile.version !== this.userService.currentVersion) || changed
                            this.userProfile.version = this.userService.currentVersion
                            this.pdfOptions.forEach(o => this._forPdfOptionNodes(o, (data) => {
                                if (data.id === PdfOption.MAIN_DESIGN_FIRST_ROUND_TRACK ||
                                    data.id === PdfOption.MAIN_DESIGN) {
                                    data.checked = true
                                } else {
                                    data.checked = false
                                }
                            }))
                            if (this.userProfile.pdfOptions) {
                                Object.entries(this.userProfile.pdfOptions).forEach(([k, v]) => {
                                    const opt = this._findPdfOptionNode(k as PdfOption)
                                    if (opt?.data) {
                                        opt.data.checked = v
                                    }
                                })
                            }
                            if (changed) {
                                this.saveUserData()
                            }
                        },
                        error: (err) => {
                            console.error('error occured', err)
                        }
                    })

                    this.userSubscriptionService.getFeatures().subscribe({
                        next: (features: UserFeatures) => {
                            this.features = features
                            if (!this.features.workOffline) {
                                this.blockUI = !navigator.onLine
                            } else {
                                this.blockUI = false
                            }
                        },
                        error: (err) => {
                            console.error('error occured', err)
                        }
                    })

                    this.brevoService.sendEvent('design-open', { email: this.user.email })
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie informacji o użytkowniku`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )

        if (this.paramsDialog) {
            this.parkourDialogs[ParkourDialogId.COMPETITION_PARAMS] = this.paramsDialog
        }
        if (this.displayOptionsDialog) {
            this.parkourDialogs[ParkourDialogId.DISPLAY_OPTIONS] = this.displayOptionsDialog
        }
        if (this.fieldSizeDialog) {
            this.parkourDialogs[ParkourDialogId.FIELD_SIZE] = this.fieldSizeDialog
        }
        if (this.debugOptionsDialog) {
            this.parkourDialogs[ParkourDialogId.DEBUG_OPTIONS] = this.debugOptionsDialog
        }
        if (this.designStatsDialog) {
            this.parkourDialogs[ParkourDialogId.DESIGN_STATS] = this.designStatsDialog
        }
        if (this.materialsDialog) {
            this.parkourDialogs[ParkourDialogId.MATERIALS] = this.materialsDialog
        }
        if (this.validationDialog) {
            this.parkourDialogs[ParkourDialogId.VALIDATION] = this.validationDialog
        }
        this.changeDetectorRef.detectChanges()
    }

    attentionForStartSet(val: boolean) {
        if (this.userProfile.attentionForStart === !val) {
            this.userProfile.attentionForStart = val
            this.userService.updateUserProfile({
                attentionForStart: val
            }).subscribe({
                error: (err) => {
                    console.error('error saving user profile', err)
                }
            })
        }
    }

    toggleLabelsVisibility(roundNo: number) {
        const toggler1 = this.uiCommands.getToggler(UiCommandId.TOGGLE_LABELS_1)
        const toggler2 = this.uiCommands.getToggler(UiCommandId.TOGGLE_LABELS_2)
        if (!toggler1 || !toggler2) {
            return
        }
        let item: ToggleMenuItem | undefined
        if (roundNo === 1) {
            item = toggler1
        } else if (roundNo === 2) {
            item = toggler2
        }
        if (item) {
            item.toggle()
            this.canvas?.obstaclePath.setConfig({
                firstRound: {
                    labelsVisible: toggler1.onOffState
                },
                secondRound: {
                    labelsVisible: toggler2.onOffState
                }
            })
            this.canvas?.jokers.forEach(j => j.setLabelsVisibility(toggler1.onOffState, 1))
        }
    }

    toggleRouteVisibility(roundNo: number) {
        const toggler1 = this.uiCommands.getToggler(UiCommandId.TOGGLE_ROUTE_1)
        const toggler2 = this.uiCommands.getToggler(UiCommandId.TOGGLE_ROUTE_2)
        const toggler3 = this.uiCommands.getToggler(UiCommandId.TOGGLE_ROUTE_3)
        if (!toggler1 || !toggler2 || !toggler3) {
            return
        }
        let item: ToggleMenuItem | undefined
        if (roundNo === 1) {
            item = toggler1
        } else if (roundNo === 2) {
            item = toggler2
        } else if (roundNo === 3) {
            item = toggler3
        }
        if (item) {
            item.toggle()
            this.canvas?.obstaclePath.setConfig({
                firstRound: {
                    pathVisible: toggler1.onOffState
                },
                secondRound: {
                    pathVisible: toggler2.onOffState
                },
                others: {
                    pathVisible: toggler3.onOffState
                }
            })
        }
    }

    toggleDistancesVisibility(roundNo: number) {
        const toggler1 = this.uiCommands.getToggler(UiCommandId.TOGGLE_DISTANCES_1)
        const toggler2 = this.uiCommands.getToggler(UiCommandId.TOGGLE_DISTANCES_2)
        const toggler3 = this.uiCommands.getToggler(UiCommandId.TOGGLE_DISTANCES_3)
        if (!toggler1 || !toggler2 || !toggler3) {
            return
        }
        let item: ToggleMenuItem | undefined
        if (roundNo === 1) {
            item = toggler1
        } else if (roundNo === 2) {
            item = toggler2
        } else {
            item = toggler3
        }
        if (item) {
            item.toggle()
            this.canvas?.obstaclePath.setConfig({
                firstRound: {
                    distancesVisible: toggler1.onOffState
                },
                secondRound: {
                    distancesVisible: toggler2.onOffState
                },
                others: {
                    distancesVisible: toggler3.onOffState
                }
            })
        }
    }

    togglePageLimitsVisibility() {
        const toggler = this.uiCommands.getToggler(UiCommandId.TOGGLE_PAGE_LIMITS)
        if (toggler) {
            toggler.toggle()
            if (this.canvas?.pageLimits) {
                this.canvas.pageLimits.visible = toggler.onOffState
            }
        }
    }

    toggleGridVisibility() {
        const toggler = this.uiCommands.getToggler(UiCommandId.TOGGLE_GRID)
        if (toggler) {
            toggler.toggle()
            if (this.canvas?.grid) {
                this.canvas.grid.visible = toggler.onOffState
            }
        }
    }

    toggleControlPointsVisibility() {
        const toggler = this.uiCommands.getToggler(UiCommandId.TOGGLE_CONTROL_POINTS)
        if (toggler) {
            toggler.toggle()
            this.canvas?.obstaclePath.setConfig({
                firstRound: {
                    pathControlPointsVisible: toggler.onOffState
                },
                secondRound: {
                    pathControlPointsVisible: toggler.onOffState
                },
                others: {
                    pathControlPointsVisible: toggler.onOffState
                }
            })
            this.updatePath()
        }
    }

    toggleLayoutLinesVisibility() {
        const toggler = this.uiCommands.getToggler(UiCommandId.TOGGLE_POSITION_LINES)
        if (toggler && this.canvas) {
            toggler.toggle()
            this.canvas.layoutLinesVisibility = toggler.onOffState
            this.updatePath()
        }
    }

    getObstaclesTabItems(): ObstaclePathNode[] {
        if (!this.canvas) {
            return []
        }
        if (this.cfg.isJokerAllowed()) {
            let val: ObstaclePathNode[] = []
            if (this.canvas?.obstaclePath.firstRound) {
                val = this.canvas.obstaclePath.firstRound.route.slice()
                if (val.length > 1) {
                    if (val[val.length - 1].obstacle.isFinishStart()) {
                        val.pop()
                        val.push(...this.canvas.jokerNodes)
                    } else if (val[val.length - 1].obstacle.isFinish()) {
                        val.splice(val.length - 1, 0, ...this.canvas.jokerNodes)
                    } else {
                        val.push(...this.canvas.jokerNodes)
                    }
                }
            }
            const rounds = this.canvas.obstaclePath.sections
            for (let i = 1; i < rounds.length; i++) {
                val = val.concat(rounds[i].route)
            }
            return val
        }
        return this.canvas.obstaclePath.sortedRoute
    }

    private _switchTabMenu(id: string) {
        const i = this.tabIds[id]
        if (i !== undefined) {
            if (this.activeTab != this.tabItems[i]) {
                this.activeTab = this.tabItems[i]
            }
            // fix for switching to the menu
            this.objectTabMenuEl?.itemClick(new Event('click'), this.tabItems[i])
            this.changeDetectorRef.detectChanges()
        }
    }

    onTabMenuActiveItemChange() {
        if (this.activeTab.id === TabIds.DESIGNS) {
            const idx = this.allMyDesigns.findIndex(d => d.localId === this.cfg.params.localId)
            if (idx >= 0) {
                this.selectedDesign = this.allMyDesigns[idx]
            }
        }
    }

    selectFocusItem(item: Selectable, point?: any, after?: Selectable) {
        this.selectItem(item, point, true, after)
    }

    adjustTablesInRightPanel(item?: Selectable) {
        if (!this.canvas?.paper) {
            return
        }
        this.selectedObjectsInList = []
        this.selectedObstaclesInList = []
        this.selectedGroupsInList = []
        let id: TabIds | undefined = undefined
        let lastId: TabIds = this.activeTab.id as TabIds || TabIds.OBJECTS
        this.selection.selectedItems.forEach(o => {
            if (!this.canvas) {
                return
            }
            if (o instanceof ParkourObject) {
                const joker: boolean = this.cfg.isJokerAllowed() && o instanceof PathObject && o.joker
                if (!this.cfg.isNoRouteMode() && o instanceof PathObject && (joker || this.canvas.obstaclePath.includes(o))) {
                    let node: ObstaclePathNode | null = null
                    if (joker) {
                        node = this.canvas.jokerNodes[o.jokerNumber - 1]
                    } else {
                        [node,] = this.canvas.obstaclePath.getPathNode(o, 1)
                    }
                    if (node) {
                        this.selectedObstaclesInList.push(node)
                        if (item === o) {
                            id = TabIds.OBSTACLES
                        }
                        lastId = TabIds.OBSTACLES
                    }
                } else if (o instanceof UserImage || o instanceof Landscape) {
                    this.selectedObjectsInList.push(o)
                    if (lastId === TabIds.IMAGES) {
                        id = TabIds.IMAGES
                    } else {
                        id = TabIds.OBJECTS
                    }
                } else {
                    this.selectedObjectsInList.push(o)
                    if (item === o) {
                        id = TabIds.OBJECTS
                    }
                    lastId = TabIds.OBJECTS
                }
            } else if (o instanceof ParkourObjectGroup) {
                this.selectedGroupsInList.push(o)
                if (item === o) {
                    id = TabIds.GROUPS
                }
                lastId = TabIds.GROUPS
            }
        })
        if (id === undefined) {
            id = lastId
        }
        this._switchTabMenu(id)
        if (id === TabIds.OBSTACLES || id === TabIds.OBJECTS) {
            const table = id === TabIds.OBSTACLES ? this.obstaclesTable : this.objectsTable
            if (table) {
                const pos = id === TabIds.OBSTACLES ? table.value.map(v => v.obstacle).indexOf(item) : table.value.indexOf(item)
                // scroll to row in objects table
                table.scrollTo({ top: this.rowHeight * pos })
            }
        }
    }

    private selectItem(item: Selectable, point?: any, makeFocus?: boolean, after?: Selectable) {
        this.selection.selectItem(item, point, makeFocus, after)
        if (item instanceof ParkourObject || item instanceof ParkourObjectGroup) {
            this.adjustTablesInRightPanel(item)
        }
    }

    selectionClear() {
        this.selection.clear()
        this.adjustTablesInRightPanel()
    }

    private deselectItem(item: Selectable) {
        this.selection.deselectItem(item)
        if (item instanceof ParkourObject || item instanceof ParkourObjectGroup) {
            this.adjustTablesInRightPanel()
        }
    }

    private deselectFocusItem(item?: Selectable) {
        if (item) {
            this.deselectItem(item)
        }
    }

    private touchDistance(touch0: Touch, touch1: Touch) {
        let dx = touch0.pageX - touch1.pageX
        let dy = touch0.pageY - touch1.pageY
        return Math.sqrt(dx * dx + dy * dy)
    }

    private onTouchStart(ev: any) {
        this.startTouches = ev.touches
        this.startZoom = this.canvas?.zoom || 100
    }

    private onTouchEnd(ev: any) {
        this.startTouches = null
        this.startZoom = 0
    }

    private onTouchMove(ev: any) {
        if (this.startTouches && this.startTouches.length == 2 &&
            ev.touches.length == 2 && this.startZoom != 0) {
            let distance0 = this.touchDistance(this.startTouches[0], this.startTouches[1])
            let distance1 = this.touchDistance(ev.touches[0], ev.touches[1])
            let scale = distance1 / distance0
            this.canvas?.setZoom(this.startZoom * scale, this.mousePoint)
        }
    }

    private onMouseWheel(ev: any) {
        const item = this.selection.getFocusItem()
        let delta = ev.deltaY || ev.detail || ev.wheelDelta

        if (item instanceof PathMidPoint) {
            item.adjustHandleFactor(delta)
            this.updatePath()
            this.saveData()
        } else {
            let stop = false
            const editable = isEditable(item)
            if (editable && editable.onMouseWheel) {
                stop = editable.onMouseWheel(delta)
            }
            if (!stop && this.userProfile.zoomWithWheel) {
                this.canvas?.setZoom(this.canvas.zoom + (delta > 0 ? -10 : 10), this.mousePoint)
            }
        }
        ev.preventDefault ? ev.preventDefault() : (ev.returnValue = false)
    }

    exitEditMode() {
        if (this.tapeMeasureMode) {
            this.tapeMeasure.end()
            this.toggleTapeMeasure(false)
            return
        }
        const focusItem = this.selection.getFocusItem()
        const editable = isEditable(focusItem)
        if (editable) {
            const del = editable.onEditEnd()
            if (del && focusItem instanceof ParkourObject) {
                this.deleteObstacle(focusItem)
            }
        }
    }

    deleteObstacle(o: ParkourObject) {
        const editable = isEditable(o)
        if (editable && editable.delete) {
            const del = editable.delete()
            if (!del) {
                this.saveData()
                return
            }
        }
        this.canvas?.deleteObject(o)
    }

    // Return objects or midpoints
    private _getPointedItems(point: paper.Point): Selectable[] {
        if (!this.canvas) {
            return []
        }
        let newSels: Selectable[] = []
        for (let o of this.canvas.objects) {
            if (o.contains(point)) {
                newSels.push(o)
            }
        }
        for (let n of this.canvas.obstaclePath.route) {
            for (let mp of n.midPoints) {
                if (mp.contains(point)) {
                    newSels.push(mp)
                }
            }
        }
        for (let o of this.canvas.objectGroups) {
            if (o.contains(point)) {
                newSels.push(o)
            }
        }
        // return object in the order of appearance on screen
        newSels = newSels.sort((a, b) => {
            if (a instanceof ParkourObject && b instanceof ParkourObject) {
                return b.layer !== a.layer ? b.layer - a.layer : 0
            }
            return 0
        })
        return newSels
    }

    private _getPointedItem(point: paper.Point): Selectable | undefined {
        const newSels = this._getPointedItems(point)
        return newSels.length > 0 ? newSels[0] : undefined
    }

    private onMouseDown(ev: any) {
        // remove focus element - it will default to body
        (document.activeElement as HTMLElement)?.blur()

        this.mouseDownPoint = ev.point
        if (this.panFieldActive) {
            return
        }
        if (this.tapeMeasureMode) {
            this.tapeMeasure.begin(ev.point)
            return
        }
        this.traceNodeIdx = undefined

        const rmb = this.rmbOnMouseDown = (ev?.event?.button === 2)
        const focusItem = this.selection.getFocusItem()
        const editable = isEditable(focusItem)
        if (!rmb && editable && editable.editMode && editable.onMouseDown) {
            const stop = editable.onMouseDown(ev.point)
            if (stop) {
                return
            }
        }

        // lock state of shift when mouse click starts and use it for further decisions
        // in mouse drag and up
        if (!rmb) {
            this.shiftOnMouseDown = paper.Key.modifiers.shift
        }

        const newSels = this._getPointedItems(ev.point)

        if (newSels.length > 0) {
            let newSel = newSels[this.selectedIndex < newSels.length ? this.selectedIndex : 0]
            newSel.selectedBeforeMouseDown = false
            // an object is clicked
            // if shift is pressed, object should be added (or removed)
            // to the existing selection
            // if shift is not pressed, just select (or deselect) the single object
            const item = this.selection.getFocusItem()
            if (!this.shiftOnMouseDown && !this.connectMode) {
                if (item !== newSel) {
                    // switching focus from one selected item to another
                    this.selectionClear()
                }
                this.selectFocusItem(newSel as ParkourObject, ev.point)

                let c = 0
                newSels.forEach(o => c += this.pointSelection.includes(o) ? 1 : 0)
                if (c === newSels.length && newSels.length === this.pointSelection.length) {
                    // current selection is the same as last time mouse was clicked
                    // point to the next selected object
                    if (!rmb && !newSel.focusLock) {
                        this.selectedIndex++
                        if (this.selectedIndex >= newSels.length) {
                            this.selectedIndex = 0
                        }
                    }
                } else {
                    // different selection
                    this.selectedIndex = 0
                    newSel = newSels[0]
                }
                this.pointSelection = newSels
            } else {
                // the object will be deselected on mouse up if now object is selected
                this.selectedIndex = 0
                newSel = newSels[0]
                if (!this.connectMode) {
                    newSel.selectedBeforeMouseDown = this.selection.isSelected(newSel)
                }
                this.selectFocusItem(newSel as ParkourObject, ev.point)
            }

            if (!rmb && this.connectMode && !newSel.selectedBeforeMouseDown) {
                const changed = this.canvas?.obstaclePath.connect(this.selection)
                this.updatePath()
                if (changed) {
                    this.saveData()
                    this.adjustTablesInRightPanel()
                }
            }
        } else {
            // if click is happening not on an object (in the field),
            // if shift is pressed, start drawing the selection rectangle
            if (!rmb && this.shiftOnMouseDown && !this.connectMode) {
                this.selection.startSelection(ev.point)
            }
            // if shift is not pressed, the selection will be cleared when mouse is up
            // if shift is pressed, the rectangle drawing will continue
            this.selection.focusItem = undefined
            this.pointSelection = []
            this.selectedIndex = 0
        }
    }

    private _processItemChangedOnMouseUp(item: Selectable, point: paper.Point) {
        // only if one item is selected
        if (this.selection.selectedItems.length != 1) {
            return false
        }
        if (item instanceof PathObject && item.selector.activity === SelectionActivity.MOVING && !item.kind.doNotDropOnPath) {
            this._dropObjectOnPath(item, point)
        } else if (item instanceof ParkourObject && item.selector.activity === SelectionActivity.ROTATING) {
            item.selector.defocus(point)
        } else if (item instanceof PathMidPoint) {
            this._dropPathOnObject(item, point)

            // if needed change midpoints count
            this.updatePath(true)
        }
    }

    // returns true when object was inserted to path
    private _dropObjectOnPath(item: PathObject, point: paper.Point): boolean {
        const ret = this.canvas?.obstaclePath.dropObjectOnPath(item, point)
        if (ret) {
            this.updatePath()
            this.adjustTablesInRightPanel()
        }
        return ret || false
    }

    private _dropPathOnObject(item: PathMidPoint, point: paper.Point) {
        this._connectNodeToPointedObject(item.parent, point)
    }

    private _connectNodeToPointedObject(pathNode: ObstaclePathNode, point: paper.Point) {
        const items = this._getPointedItems(point).filter(o => o instanceof PathObject)
        const ret = this.canvas?.obstaclePath.connectNodeToObjects(pathNode, items)
        if (ret) {
            this.updatePath()
            this.adjustTablesInRightPanel()
        }
    }

    @HostListener('document:dblclick', ['$event'])
    onDoubleClick(event: PointerEvent) {
        if (!this.canvas?.paper || !event.target || !(event.target instanceof HTMLCanvasElement) || event.target.localName !== 'canvas') {
            return
        }
        let ne = this.canvasElement?.nativeElement
        if (!ne) {
            return
        }
        let pv = new paper.Point(event.clientX - ne.offsetLeft, event.clientY - ne.offsetTop)
        pv = this.canvas.paper.view.viewToProject(pv)

        const sel = this._getPointedItem(pv)
        if (sel) {
            if (sel instanceof PathObject) {
                // open side menu if closed before calling handler
                this.obstaclesListCollapsed = false
            }

            if (sel.doubleClick(pv)) {
                this.updatePath()
                this.saveData()
            }
        } else {
            const mp = this.canvas.obstaclePath.tryToAddMidPoint(pv)
            if (mp) {
                this.selectFocusItem(mp, pv)
                this.updatePath()
            }
        }
    }

    private onMouseUp(ev: any) {
        if (this.panFieldActive) {
            return
        }
        if (this.tapeMeasureMode) {
            this.tapeMeasure.freeze()
            return
        }
        const rmb = this.rmbOnMouseDown
        // when click is finished and it was done on an object
        let item: Selectable | null = this.selection.getFocusItem()
        if (item) {
            const editable = isEditable(item)
            if (!rmb && editable && editable.editMode && editable.onMouseUp) {
                const stop = editable.onMouseUp(ev.point)
                if (stop) {
                    return
                }
            }
            if (!rmb && item.changed) {
                // single object finished being dragged
                this._processItemChangedOnMouseUp(item, ev.point)
                // if object was changed (moved or rotated) during mouse down,
                // save the changed state
                this.saveData()
                // update the object selection for the next mouse down
                this.pointSelection = this._getPointedItems(ev.point)
                this.selectedIndex = this.pointSelection.indexOf(item)
                if (this.selectedIndex < 0) {
                    this.selectedIndex = 0
                }
                item.changed = false
            } else if (!rmb && item.selectedBeforeMouseDown) {
                // if a click happened on an object without dragging,
                // finish the selection toggle by deselecting the object if it was selected
                // when mouse was pressed down
                this.deselectFocusItem(item)
                if (this.connectMode) {
                    const changed = this.canvas?.obstaclePath.connect(this.selection)
                    this.updatePath()
                    if (changed) {
                        this.saveData()
                    }
                }
            } else {
                // mouse up without actions - click on an object
                if (!this.shiftOnMouseDown && !this.connectMode && this.pointSelection.length > 0) {
                    const sel = this.pointSelection[this.selectedIndex]
                    if (this.selection.getFocusItem() !== sel) {
                        this.selectionClear()
                        this.selectFocusItem(sel as ParkourObject, ev.point)
                    }
                }
            }
        } else if (!this.shiftOnMouseDown && !this.selection.keepSelected) {
            // when the click happend in the field (not on object),
            // if shift is not pressed, clear any selection active
            // but only if it was not modified during mouse drag
            this.selectionClear()
            this.deselectFocusItem()
        } else if (!this.connectMode) {
            // when shift was pressed and there is no selected object,
            // finish drawing selection rectangle
            this.selection.endSelection()
        }
        this.selection.keepSelected = false
        this.canvas?.drawPageLimits()
    }

    private onMouseDrag(ev: any) {
        if (!this.canvas || this.rmbOnMouseDown || this.panFieldActive) {
            return
        }

        const focusItem = this.selection.getFocusItem()
        const editable = isEditable(focusItem)
        if (editable && editable.editMode && editable.onMouseDrag) {
            const stop = editable.onMouseDrag(ev.delta)
            if (stop) {
                return
            }
        } else if (focusItem) {
            // When an object was clicked and dragged, it will cause:
            // - when one object is selected, it is moved or rotated depending on where
            //   the click happened
            // - when many objects are selected, all are moved together always
            // Dragging of clicked object can be with or without shift pressed
            let pathNeedsUpdate = this.dragSelection(ev.delta, ev.point)

            if (pathNeedsUpdate) {
                // update path but do not change count of midpoints if midpoint is being drag now;
                // midpoints count adjustment will be made later, on mouse up (drag end)
                this.updatePath(!(focusItem instanceof PathMidPoint))
            }
        } else if (!paper.Key.modifiers.shift) {
            // when there is no clicked object (click in field) and shift is not pressed
            // the objects are deselected
            this.selectionClear()
        } else if (!this.connectMode) {
            // when there is no clicked object (click in field) and shift is pressed
            // a selection rectangle is expanded to select more objects
            this.selection.expandSelection(ev.point)
            for (let o of this.canvas.objects) {
                if (o instanceof PathObject) {
                    this.canvas.obstaclePath.getPathNodes(o).forEach(([n, _]) => {
                        n.midPoints.forEach(mp => {
                            if (this.selection.rectangle && mp.getPosition().isInside(this.selection.rectangle)) {
                                this.selection.selectItem(mp, null, false)
                            } else if (!this.selection.inLastSelection(mp)) {
                                this.selection.deselectItem(mp)
                            }
                        })
                    })
                }
                if (this.selection.rectangle && o.isInside(this.selection.rectangle)) {
                    this.selectItem(o, null, false)
                } else if (!this.selection.inLastSelection(o)) {
                    this.deselectItem(o)
                }
            }
        }
        this.canvas.drawPageLimits()
        ev.stop()
    }

    // move or rotate selected objects and take care of the surrounding midpoints
    // and curve handles
    private dragSelection(delta: paper.Point, point: paper.Point): boolean {
        if (!this.canvas) {
            return false
        }
        let items: Selectable[] = [...this.selection.selectedItems]

        // remove from selection individually selected items, which are already inside a selected group
        this.selection.selectedItems.forEach(i => {
            if (i instanceof ParkourObjectGroup) {
                i.children.forEach(c => {
                    const idx = items.indexOf(c)
                    if (idx >= 0) {
                        items.splice(idx, 1)
                    }
                })
            }
        })

        // move/drag selected items and do some adjustments
        let pathNeedsUpdate = false
        const routeOldPos = this.canvas.obstaclePath.getRoutePositions()
        if (items.length === 1) {
            // single object selected
            const o = items[0]
            o.drag(delta, point)
            pathNeedsUpdate ||= canBeInPath(o)
        } else if (items.length > 1) {
            let rotatingItem: ParkourObject | ParkourObjectGroup | undefined = undefined // item on which handle user clicked
            for (let i = 0; i < items.length; i++) {
                const o = items[i]
                if (o instanceof ParkourObject || o instanceof ParkourObjectGroup) {
                    if (o.selector.activity === SelectionActivity.ROTATING) {
                        rotatingItem = o
                        break
                    }
                }
            }
            if (rotatingItem) {
                const center: paper.Point = items.reduce((v, o) => v.add(o.getPosition()), new paper.Point(0, 0)).divide(items.length)
                const before: paper.Point = point.subtract(delta)

                // vectors from the center to before and after drag
                const pointC = point.subtract(center)
                const beforeC = before.subtract(center)

                // angle to rotate the grup against the group center
                const angle = pointC.angle - beforeC.angle

                // scale factor how the group should grow or shrink
                const scale = pointC.length / beforeC.length

                pathNeedsUpdate = this.canvas.obstaclePath.rotateAndScaleGroup(items, center, angle, Key.modifiers.shift ? 1 : scale) || pathNeedsUpdate
            } else {
                pathNeedsUpdate = this.canvas.obstaclePath.moveGroup(items, delta) || pathNeedsUpdate
            }
        }
        this.canvas.obstaclePath.adjustGroupMidPoints(routeOldPos, items)
        this.updateGroupsOfItems(items)
        return pathNeedsUpdate
    }

    private updateGroupsOfItems(items: Selectable[]) {
        const groupsToAdjust: ParkourObjectGroup[] = []
        items.forEach(i => {
            const parent = i.parentSelector
            if (parent && parent instanceof ParkourObjectGroup) {
                if (!groupsToAdjust.includes(parent)) {
                    groupsToAdjust.push(parent)
                }
            }
        })
        groupsToAdjust.forEach(g => {
            g.findBestAngle()
            g.update()
        })
    }

    private onMouseMove(ev: any) {
        this.mousePoint = ev.point
        if (!this.canvas?.paper) {
            return
        }
        if (this.panFieldActive) {
            // move the whole field
            const delta = new paper.Point(ev.event.movementX, ev.event.movementY).divide(this.canvas.paper.view.zoom)
            this.canvas.moveArena(delta)
        } else if (this.tapeMeasureMode) {
            if (!paper.Key.modifiers.shift) {
                this.tapeMeasure.extend(ev.point)
            } else {
                this.tapeMeasure.stretch(ev.point)
            }
            return
        } else {
            const focusItem = this.selection.getFocusItem()
            const editable = isEditable(focusItem)
            if (editable && editable.editMode && editable.onMouseMove) {
                const stop = editable.onMouseMove(ev.point)
                if (stop) {
                    return
                }
            }
        }
    }

    private onMouseLeave(ev: any) {
        this.mousePoint = null
    }

    doUndoRedo(undo: boolean) {
        if (this.loadingData) {
            return
        }
        let obj
        if (undo) {
            obj = this.undo.undo()
        } else {
            obj = this.undo.redo()
        }
        if (obj) {
            this.performingUndo = true
            this._restoreParkour(obj)
            this.updatePath()
            this.saveData()
            this.performingUndo = false
        } else {
            if (undo) {
                showInfoBox(this.msgSvc, $localize`Cofanie operacji`, $localize`Nie ma operacji do wycofania`)
            } else {
                showInfoBox(this.msgSvc, $localize`Powtarzanie operacji`, $localize`Nie ma operacji do powtórzenia`)
            }
        }
    }

    saveData() {
        this.designStatsDialog?.update()
        if (!this.canvas || this.cfg.params.author !== this.user?.uid || this.canvas.obstaclePath.route.length === 0) {
            console.log('saveData aborted')
            return
        }

        console.log('saveData', this.saved++)
        if (!this.cfg.params.localId || !this.cfg.params.createdAt) {
            return
        }
        if (this.loadingData) {
            return
        }
        this.cfg.params.origAuthor = null

        const route = this.canvas.obstaclePath.toJson()
        this.cfg.setRoute(route)

        let array: ParkourObject[] = this.canvas.objects.sort((a, b) =>
            b.layer !== a.layer ? b.layer - a.layer : b.index - a.index
        )
        let objects = array.map(o => o.toJson())
        this.cfg.setObjects(objects)

        let groups = this.canvas.objectGroups.map(g => g.toJson()).filter(g => g.uuids.length > 0)
        this.cfg.setGroups(groups)

        if (!this.performingUndo) {
            this.undo.store(this.cfg.params)
        }

        this.cfg.params.authorName = this.auth.getUserDisplayName()

        let pngPreview: PngImage
        this.canvas.exportToPng(ExportMode.SNAPSHOT, 1024, true, false, false).then(ret => pngPreview = ret).finally(() => {
            this.subs.add(
                this.store.updateDesign(this.cfg.params, pngPreview?.data).subscribe({
                    next: (resp: any) => {
                    },
                    error: (err) => {
                        console.error('error occured', err)
                        showErrorBox(this.msgSvc, $localize`Zapisywanie projektu`, $localize`Wystąpił nieznany błąd`)
                    }
                })
            )
            if (pngPreview) {
                const design = this.allMyDesigns.find(v => v.localId === this.cfg.params.localId)
                if (design) {
                    (design as any).png = pngPreview.data
                }
            }
        }).catch(err => {
            console.error('error occured', err)
            showErrorBox(this.msgSvc, $localize`Zapisywanie projektu`, $localize`Wystąpił nieznany błąd`)
        })
    }

    private loadData(loadOtherEventDesigns: boolean, switchToProjectsTab?: boolean) {
        this.startInProgress()
        if (!this.cfg.params.localId) {
            showErrorBox(this.msgSvc, $localize`Błąd przy ładowaniu danych projektu`, $localize`Brak designId`)
            this.stopInProgress()
            return
        }

        this.loadingData = true

        this.subs.add(
            this.store.loadDesign(this.cfg.params.localId, this.cfg.params.author).subscribe({
                next: (data: DesignSchema | null) => {
                    if (!data) {
                        console.error('loaded empty design data')
                        return
                    }
                    this.designData = data
                    this._restoreParkour(this.designData, switchToProjectsTab)
                    this.undo.store(this.designData)
                    this.canvas?.resetView()

                    if (this.firstDataLoading) {
                        this.firstDataLoading = false
                        if (this.designData.eventName) {
                            this.filteringEvent = this.designData.eventName
                            this.designesTableInitFilter = {
                                eventName: [{ value: this.filteringEvent, matchMode: 'equals' }]
                            }
                        }
                    }

                    if (this.newDesign) {
                        this.newDesign = false
                        this.saveData()
                    }

                    if (loadOtherEventDesigns) {
                        this.loadLocalDesigns()
                    } else {
                        this.setupEventDesigns()
                    }
                },
                error: (err) => {
                    this.loadingData = false
                    this.stopInProgress()
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie danych projeku`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    private loadLocalDesigns(callback?: () => any) {
        this.subs.add(
            this.store.getLocalDesigns().subscribe({
                next: (designs: DesignSchema[]) => {
                    this.allMyDesigns = designs
                    this.allMyDesigns.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                    this.setupEventDesigns()
                    if (callback) {
                        callback()
                    }
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie lokalnych projeków`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    private setupEventDesigns() {
        // update current design in the designs list
        for (let idx = 0; idx < this.allMyDesigns.length; idx++) {
            const d = this.allMyDesigns[idx]
            if (d.localId === this.cfg.params.localId) {
                let design: any = this.allMyDesigns[idx]
                const png = design.png
                const pngUrl = design.pngUrl
                design = this.allMyDesigns[idx] = structuredClone(this.cfg.params)
                if (png) {
                    design.png = png
                }
                if (pngUrl) {
                    design.pngUrl = pngUrl
                }
            }
        }

        // populate and sort all my events list
        this.myEvents = []
        this.allMyDesigns.filter(e => e.eventName).forEach(e => {
            if (!this.myEvents.find(v => v.eventName === e.eventName)) {
                this.myEvents.push({
                    eventName: e.eventName,
                    eventDate: e.eventDate?.toLocaleDateString(this.locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
                })
            }
        })
        this.myEvents.sort((a, b) => {
            if (a.eventDate && b.eventDate) {
                const x = b.eventDate.localeCompare(a.eventDate)
                if (x !== 0) {
                    return x
                }
            }
            return b.eventName.localeCompare(a.eventName)
        })
        this.myEventsWithAll = structuredClone(this.myEvents)
        this.myEventsWithAll.unshift({ eventName: this.noEventsFilter })

        if (!this.myEventsWithAll.find(e => e.eventName === this.filteringEvent)) {
            this.filteringEvent = this.noEventsFilter
            this.applyEventFilter()
        }
    }

    applyEventFilter() {
        if (!this.designsTable) {
            return
        }
        if (this.filteringEvent === this.noEventsFilter) {
            this.designsTable.clear()
        } else {
            this.designsTable.filter(this.filteringEvent, 'eventName', 'equals')
        }
        this._loadCompSorterMode()
    }

    designsSort(mode: SortMode<DesignSchema>) {
        this.compSorter.toggleSort(mode)
        const patch: UserProfile = {}
        if (this.filteringEvent === this.noEventsFilter) {
            this.userProfile.compSorterForAllEvents = patch.compSorterForAllEvents = this.compSorter.toJson()
        } else {
            this.userProfile.compSorter = patch.compSorter = this.compSorter.toJson()
        }
        this.userService.updateUserProfile(patch)
    }

    private _loadCompSorterMode() {
        if (this.filteringEvent === this.noEventsFilter) {
            if (this.userProfile.compSorterForAllEvents) {
                this.compSorter.fromJson(this.userProfile.compSorterForAllEvents)
            }
        } else {
            if (this.userProfile.compSorter) {
                this.compSorter.fromJson(this.userProfile.compSorter)
            }
        }
    }

    deleteObjectsFromUi(items: Selectable[]) {
        const needUpdate = this.deleteObjects(items.slice(0))
        if (needUpdate) {
            this.updatePath()
            this.saveData()
        }
    }

    autoRotateSelectedObjects() {
        let needUpdate = false
        for (let o of this.selection.selectedFlatItems) {
            if (o instanceof PathObject) {
                this.canvas?.obstaclePath.autoRotate(o)
                needUpdate = true
            }
        }
        if (needUpdate) {
            this.updatePath()
            this.saveData()
        }
    }

    rotateSelection(angle: number) {
        this.selection.rotate(angle)
        this.updatePath()
        this.saveData()
    }

    moveSelection(xDelta: number, yDelta: number) {
        if (this.dragSelection(new paper.Point(xDelta, yDelta), new paper.Point(0, 0))) {
            this.updatePath()
            this.saveData()
        }
    }

    cloneFocusItem() {
        const o = this.selection.getFocusItem()
        if (o && o instanceof ParkourObject) {
            if (this.mousePoint.isInside(this.canvas?.field) && o instanceof PathObject || !(o instanceof PathObject)) {
                if (!this.checkNewObjectPreconditions(o.kind)) {
                    return
                }
                this.canvas?.createObject({ ...o.toJson(), uuid: undefined, x: this.mousePoint.x, y: this.mousePoint.y })
                this.updatePath()
                this.saveData()
            }
        }
    }

    removeObjectsFromGroup(objects: Selectable[]) {
        let changed = false
        objects.forEach(i => changed = this.removeFromGroup(i) || changed)
        if (changed) {
            this.saveData()
        }
    }

    private removeFromGroup(item: Selectable): boolean {
        const parent = item.parentSelector
        if (parent instanceof ParkourObjectGroup) {
            if (parent.remove(item) < 2) {
                this.canvas?.deleteObject(parent)
            }
            return true
        }
        return false
    }

    deleteObjects(objects: Selectable[]): boolean {
        let needUpdate = false
        for (let o of objects) {
            if (o instanceof ParkourObject) {
                this.deleteObstacle(o as ParkourObject)
            } else if (o instanceof PathMidPoint) {
                const idx = o.parent.midPoints.indexOf(o)
                if (idx >= 0) {
                    o.parent.midPoints.splice(idx, 1)
                    o.destroy()
                    needUpdate = true
                }
            } else if (o instanceof ParkourObjectGroup) {
                const children = [...o.children]
                this.canvas?.deleteObject(o)
                needUpdate = this.deleteObjects(children) || needUpdate
            }
            needUpdate = this.removeFromGroup(o) || needUpdate
            this.deselectItem(o)
        }
        return needUpdate
    }

    private _restoreParkour(data: any, switchToProjectsTab?: boolean) {
        if (!this.canvas?.paper) {
            return
        }
        // data validation
        if (data.objects.length === 0 && data.route.length > 0) {
            showErrorBox(this.msgSvc, $localize`Błąd danych projektu`, $localize`Niespójne dane projektu`)
            return
        }
        this.loadingData = true
        this.selectionClear()
        this.paramsDialog?.initialize(false)
        this.subs.add(this.canvas.loadDesign(data).pipe(timeout(5000)).subscribe({
            next: () => {
                this._restoreParkourFinish(switchToProjectsTab)
            },
            error: (err) => {
                if (err instanceof TimeoutError) {
                    console.log('Objects loading timeout.')
                    this._restoreParkourFinish(switchToProjectsTab)
                } else {
                    this.loadingData = false
                    this.stopInProgress()
                    showErrorBox(this.msgSvc, $localize`Błąd ładowania projektu`, $localize`Nieznany błąd`)
                }
            }
        }))
    }

    private _restoreParkourFinish(switchToProjectsTab?: boolean) {
        this.loadingData = false
        this.stopInProgress()
        this.selection = new Selection()

        if (!this.cfg.params.localId) {
            showErrorBox(this.msgSvc, $localize`Błąd danych projektu`, $localize`Brak designId`)
            return
        }

        // table language will be changed to the current UI language,
        // if previously the table language was the same as UI language and current UI language is
        // one of the languages that table supports
        const prev = localStorage.getItem('prevLocale')
        if (prev) {
            if (prev !== this.locale && prev === this.cfg.params.tableLanguage &&
                (this.locale === 'pl' || this.locale === 'en' || this.locale === 'de')) {
                this.cfg.params.tableLanguage = this.locale
                this.saveData()
            }
            localStorage.removeItem('prevLocale')
        }

        if (switchToProjectsTab) {
            this._switchTabMenu(TabIds.DESIGNS)
        }

        if (this.cfg.isNoRouteMode()) {
            this.tabItems[0].disabled = true
            if (this.activeTab === this.tabItems[0]) {
                this._switchTabMenu(TabIds.OBJECTS)
            }
        } else {
            this.tabItems[0].disabled = false
            if (this.activeTab === this.tabItems[1]) {
                this._switchTabMenu(TabIds.OBSTACLES)
            }
        }
        this.buildMenuItems()
        this.validateParkour(false)
    }

    addOxer() {
        if (this.mousePoint.isInside(this.canvas?.field)) {
            this.canvas?.createObject({
                kind: 'oxer-vector',
                x: this.mousePoint.x,
                y: this.mousePoint.y,
                angle: 0
            })
            this.updatePath()
            this.saveData()
        }
    }

    private _clearManualLabels(objects: Selectable[]) {
        let needUpdate: boolean = false
        objects.forEach(o => {
            if (o instanceof Obstacle) {
                needUpdate = o.clearManualLabels() || needUpdate
            }
        })
        if (needUpdate) {
            this.updatePath()
            this.saveData()
        }
    }

    clearManualLabels() {
        if (this.canvas) {
            this._clearManualLabels(this.canvas.obstacles)
        }
    }

    clearSelectedManualLabels() {
        this._clearManualLabels(this.selection.selectedItems)
    }

    @HostListener('document:keyup', ['$event'])
    handlekeyUp(event: KeyboardEvent) {
        if (!this.inProgress) {
            if (event.key === ' ') {
                this.cursor = this.panFieldPrevCursor || Cursor.DEFAULT
                this.panFieldActive = false
            } else {
                this.uiCommands.shortcuts.onKeyUp(event)
            }
        }
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (!this.inProgress) {
            if (event.key === ' ') {
                if (!this.panFieldActive) {
                    this.panFieldPrevCursor = this.cursor
                    this.cursor = Cursor.PAN_FIELD
                    this.panFieldActive = true
                }
            } else {
                this.uiCommands.shortcuts.onKeyDown(event)
            }
        }
        if (event.key === ' ' && document.activeElement === document.body) {
            event.preventDefault()
            event.stopPropagation()
        }
    }

    private replaceObject(from: ParkourObject, to: ParkourObject | null) {
        let selected = this.selection.isSelected(from)
        let focus = (this.selection.focusItem === from)
        this.deselectFocusItem(from)

        this.canvas?.replaceObject(from, to)

        if (selected && to) {
            this.selectItem(to, null, focus)
        }
        this.adjustTablesInRightPanel()
    }

    private checkNewObjectPreconditions(objKind: ParkourObjectTemplate): boolean {
        if (!this.canvas) {
            return false
        }
        if (!this.features.customImagesAndLogos && objKind.kind === 'user-image') {
            showInfoBox(this.msgSvc, $localize`Dodawanie przeszkody lub innego obiektu`,
                $localize`Twój plan cenowy nie pozwala na dodanie tego obiektu.` +
                '\n\n' + $localize`Możesz zmienić plan cenowy w menu Pomoc.`)
            return false
        }

        if (this.canvas.objects.length > this.features.obstacleLimit) {
            const v = this.features.obstacleLimit
            showInfoBox(this.msgSvc, $localize`Dodawanie przeszkody lub innego obiektu`,
                $localize`Nie można dodać więcej przeszkód, limit ${v} obiektów osiągnięty.` +
                '\n\n' + $localize`Możesz zmienić plan cenowy w menu Pomoc.`)
            return false
        }

        const obstacles = this.canvas.obstacles
        const startsCount = obstacles.filter(o => o.isStart()).length
        const finishCount = obstacles.filter(o => o.isFinish()).length
        const finishStartCount = obstacles.filter(o => o.isFinishStart()).length
        const header = $localize`Dodawanie przeszkody lub innego obiektu`
        if (objKind.kind === 'start') {
            if (startsCount > 1) {
                showInfoBox(this.msgSvc, header, $localize`Nie można dodać trzeciego startu`)
                return false
            }
            if (finishStartCount > 0) {
                showInfoBox(this.msgSvc, header, $localize`Nie można dodać drugiego Start, jeśli jest już Meta/Start`)
                return false
            }
        }
        if (objKind.kind === 'finish') {
            if (finishCount > 1) {
                showInfoBox(this.msgSvc, header, $localize`Nie można dodać trzeciej mety`)
                return false
            }
            if (finishStartCount > 0 && finishCount > 0) {
                showInfoBox(this.msgSvc, header, $localize`Nie można dodać drugiej mety, jeśli jest już Meta/Start`)
                return false
            }
        }
        if (objKind.kind === 'finish-start') {
            if (finishStartCount > 0) {
                showInfoBox(this.msgSvc, header, $localize`Nie można dodać kolejnego Meta/Start`)
                return false
            }
            if (startsCount > 1) {
                showInfoBox(this.msgSvc, header, $localize`Nie można dodać Meta/Start, jeśli jest już drugi Start`)
                return false
            }
        }

        return true
    }

    private addObject(objKind: ParkourObjectTemplate, point: paper.Point, angle?: number, doNotDropOnPath?: boolean): ParkourObject[] {
        if (!this.checkNewObjectPreconditions(objKind) || !this.canvas) {
            return []
        }

        let o, ret: ParkourObject[] = []
        if (objKind.kind === 'table-banner') {
            // put banner at the field edge closest to the dropping point
            const dr = Math.abs(this.canvas.field.right - point.x)
            const dl = Math.abs(point.x - this.canvas.field.left)
            const dt = Math.abs(point.y - this.canvas.field.top)
            const db = Math.abs(this.canvas.field.bottom - point.y)
            const minDist = Math.min(dr, dl, dt, db)
            if (dr === minDist) {
                // right edge
                ret = this.canvas.setupBanners(BannerPosition.RIGHT)
            } else if (dl === minDist) {
                // left edge
                ret = this.canvas.setupBanners(BannerPosition.LEFT)
            } else if (db === minDist) {
                // bottom edge
                ret = this.canvas.setupBanners(BannerPosition.BOTTOM)
            } else if (dt === minDist) {
                // top edge
                ret = this.canvas.setupBanners(BannerPosition.TOP)
            }
            this.canvas.updateZoom()
            this.canvas.centerView()
        } else if (objKind.kind === 'object-generator') {
            const generator = this.objectFactory.getObject({
                cfg: this.cfg,
                canvas: this.canvas,
                store: this.store,
                subs: this.subs,
                object: {
                    kind: objKind,
                    x: point.x,
                    y: point.y,
                    options: objKind.options
                },
                view: this,
            })
            if (generator instanceof ObjectGenerator) {
                const gen = generator.generate()
                gen.objects.forEach(o => this.addObject(o.objKind, o.point, o.angle))
                gen.groups.forEach(g => {
                    const objs: ParkourObject[] = []
                    g.forEach(o => objs.push(...this.addObject(o.objKind, o.point, o.angle, o.doNotDropOnPath)))
                    this._groupObjects(objs)
                })
            }
        } else {
            // adding a non-table-banner object
            o = this.canvas.createObject({
                kind: objKind,
                x: point.x,
                y: point.y,
                angle: angle,
                decorations: objKind.decorations,
                content: objKind.content,
                border: objKind.border,
                fontFamily: objKind.fontFamily,
                txtScale: objKind.txtScale,
                smoothing: objKind.smoothing,
                imageId: objKind.imageId,
            })
            ret = [o]
            const editable = isEditable(o)
            if (editable && o instanceof Drawing) {
                editable.onEditStart(point, 0)
            } else if (o instanceof PathObject) {
                if (o.isStart()) {
                    // add second start to the tail
                    this.canvas.obstaclePath.add(o, [], false, true, NodeFlags.NONE)
                } else if (!doNotDropOnPath && !o.kind.doNotDropOnPath && !o.isFinish()) {
                    // try to add to the path in the place where object was dropped
                    this._dropObjectOnPath(o, point)
                }
                this.updatePath()
            }
            if (objKind.kind === 'user-image') {
                this.openImageSelectTab(true)
            }
        }
        this.selectionClear()
        if (o) {
            this.selectFocusItem(o, point) // will clear if currently obstacle is selected
        }
        this.saveData()
        this.canvas.drawPageLimits()
        return ret
    }

    straightenForwardPath(objects: Selectable[]) {
        objects.forEach(o => {
            if (o instanceof PathObject) {
                const nodes = this.canvas?.obstaclePath.getPathNodes(o) || []
                nodes.forEach(([n, _]) => n.clearMidPoints())
            }
        })
        this.updatePath()
        this.saveData()
    }

    straightenAllForwardPaths() {
        this.canvas?.obstaclePath.route.forEach(n => {
            n.clearMidPoints()
        })
        this.updatePath()
        this.saveData()
    }

    detachFromPath(objects: any[], roundNo?: number) {
        for (let o of objects) {
            if (o instanceof PathObject) {
                this.canvas?.obstaclePath.delete(o, roundNo)
            }
        }
        this.updatePath()
        this.saveData()
        this.adjustTablesInRightPanel()
    }

    deleteNode(n: ObstaclePathNode) {
        const idx = this.canvas?.obstaclePath.getNodeIndex(n) || -1
        if (idx >= 0) {
            this.canvas?.obstaclePath.deleteNode(idx, true)
            this.updatePath()
            this.saveData()
            this.adjustTablesInRightPanel()
        }
    }

    reverseDirection(objects: any[], roundNo?: number) {
        for (let o of objects) {
            if (o instanceof PathObject) {
                o.switchDirections(roundNo)
            }
        }
        this.updatePath()
        this.saveData()
    }

    selectAllObjects() {
        this.canvas?.objects.forEach(o => this.selectItem(o))
    }

    updatePath(changeMidPointsCount?: boolean) {
        this.canvas?.updatePath(changeMidPointsCount)
        this.validateParkour(false)
    }

    onCanvasParentResized(ev: any) {
        this.canvas?.changeViewSize()
    }

    onRowChange(ev: any, tabIndex: number) {
        const oe = ev.originalEvent
        // when user keeps meta key pressed, like selecting multiple rows in a panel, keep the
        // selection from the other panel
        if (oe instanceof PointerEvent && !oe.metaKey || !(oe instanceof PointerEvent)) {
            if (tabIndex === 0) {
                this.selectedObjectsInList = []
                this.selectedGroupsInList = []
            } else if (tabIndex === 1) {
                this.selectedObstaclesInList = []
                this.selectedGroupsInList = []
            } else {
                this.selectedObjectsInList = []
                this.selectedObstaclesInList = []
            }
        }
        this.selection.clear()
        this.selectedObjectsInList.forEach(obj => {
            this.selection.selectItem(obj, null, true)
        })
        this.selectedObstaclesInList.forEach(obj => {
            this.selection.selectItem(obj.obstacle, null, true)
        })
        this.selectedGroupsInList.forEach(obj => {
            this.selection.selectItem(obj, null, true)
        })
        this.changeDetectorRef.detectChanges()
    }

    makeCombination(items: Selectable[]) {
        const obstacles: ObstacleWithBars[] = items.filter(s => s instanceof ObstacleWithBars) as ObstacleWithBars[]
        this.canvas?.obstaclePath.putInCombination(obstacles)
        this.updateGroupsOfItems(obstacles)
        this.updatePath()
        this.saveData()
    }

    traceForwardPath() {
        if (!this.canvas) {
            return
        }
        const item = this.selection.getFocusItem()
        if (item instanceof PathObject) {
            let idx: number
            if (this.traceNodeIdx === undefined) {
                [, idx] = this.canvas.obstaclePath.getPathNode(item, 0)
                this.traceNodeIdx = idx
            } else {
                idx = this.traceNodeIdx
            }
            if (idx < this.canvas.obstaclePath.route.length) {
                const node = this.canvas.obstaclePath.route[idx]
                if (node && node.forwardPath) {
                    this.traceNodeIdx = idx
                    let i = 0
                    const obj = new paper.Shape.Circle({
                        radius: 35,
                        center: node.forwardPath.getPointAt(i),
                        fillColor: '#08f',
                        strokeWidth: null
                    })
                    obj.onFrame = () => {
                        if (!this.canvas) {
                            return
                        }
                        if (node.forwardPath && i < node.forwardPath.length) {
                            obj.position = node.forwardPath.getPointAt(i)
                            i += 30
                        } else {
                            if (obj.isInserted()) {
                                obj.remove()
                            }
                            if (idx < this.canvas.obstaclePath.route.length - 1) {
                                this.traceNodeIdx = idx + 1
                                this.selection.clear()
                                this.selectFocusItem(this.canvas.obstaclePath.route[idx + 1].obstacle)
                            } else {
                                this.traceNodeIdx = undefined
                            }
                        }
                    }
                    return
                }
            }
        }
        this.traceNodeIdx = undefined
    }

    private _estimateImagePosInPDF(pageWidth: number, pageHeight: number, image: PngImage): number[] {
        let w, h
        if (image.width / image.height > pageWidth / pageHeight) {
            w = pageWidth
            h = pageWidth * image.height / image.width
        } else {
            h = pageHeight
            w = pageHeight * image.width / image.height
        }
        const marginX = Math.max((pageWidth - w) / 2, 0)
        const marginY = Math.max((pageHeight - h) / 2, 0)
        return [w, h, marginX, marginY]
    }

    private _findPdfOptionNode(option: PdfOption, tree?: TreeNode<PdfOptionsData>[]): TreeNode<PdfOptionsData> | undefined {
        for (let o of (tree || this.pdfOptions)) {
            if (o.data?.id === option) {
                return o
            }
            if (o.children) {
                const n = this._findPdfOptionNode(option, o.children)
                if (n) {
                    return n
                }
            }
        }
        return undefined
    }

    private _forPdfOptionNodes(node: TreeNode<PdfOptionsData>, callback: (data: PdfOptionsData) => void) {
        if (node?.data) {
            callback(node.data)
        }
        node.children?.forEach(c => this._forPdfOptionNodes(c, callback))
    }

    private _disablePdfOptions(options: PdfOption[]) {
        options.forEach(o => {
            const node = this._findPdfOptionNode(o)
            if (node) {
                this._forPdfOptionNodes(node, (data) => {
                    data.disabled = true
                    data.checked = false
                    data.tooltip = $localize`Twój plan cenowy nie pozwala na zapis lub druk tej strony.` + '\n\n' + $localize`Możesz zmienić plan cenowy w menu Pomoc.`
                })
            }
        })
    }

    openPDFDialog(operation: string) {
        if (!this.features.saveToPDFAndPrint) {
            if (operation === 'print') {
                showInfoBox(this.msgSvc, $localize`Drukowanie projektu`,
                    $localize`Twój plan cenowy nie pozwala na drukowanie projektu.` +
                    '\n\n' + $localize`Możesz zmienić plan cenowy w menu Pomoc.`)
            } else {
                showInfoBox(this.msgSvc, $localize`Zapis projektu do PDF`,
                    $localize`Twój plan cenowy nie pozwala na zapis projektu do PDF.` +
                    '\n\n' + $localize`Możesz zmienić plan cenowy w menu Pomoc.`)
            }
            return
        }
        this.pdfOptions.forEach(p => this._forPdfOptionNodes(p, (data) => {
            data.disabled = false
            data.tooltip = undefined
        }))
        if (!this.features.printMasterPlanAndTables) {
            this._disablePdfOptions([PdfOption.MASTERPLAN, PdfOption.OBJECTS, PdfOption.OBSTACLES])
        }
        this.pdfOperation = operation
        this.printDlgVisible = true
    }

    getPDFDialogOperation() {
        if (this.pdfOperation === 'print') {
            return $localize`Drukuj`
        }
        return $localize`Zapisz jako PDF`
    }

    generatePDF() {
        if (!this.canvas) {
            return
        }

        const canvas = this.canvas
        this.startInProgress()
        this.selectionClear()
        this.toggleTapeMeasure(false)

        let winPrint: any
        if (this.pdfOperation === 'print') {
            winPrint = window.open('', '_blank')
        }
        const width = 2048
        let regularPng: PngImage
        let blankPng: PngImage
        const opt1 = this._findPdfOptionNode(PdfOption.MAIN_DESIGN_FIRST_ROUND_TRACK)
        const opt2 = this._findPdfOptionNode(PdfOption.MAIN_DESIGN_SECOND_ROUND_TRACK)
        this.canvas.exportToPng(ExportMode.REGULAR, width, opt1?.data?.checked || false, opt2?.data?.checked || false, false).then(ret => {
            regularPng = ret
            return canvas.exportToPng(ExportMode.ARENA, width, false, false, false)
        }).then(ret => {
            blankPng = ret
            const opt = this._findPdfOptionNode(PdfOption.MASTERPLAN_POSITION_LINES)
            return canvas.exportToPng(ExportMode.MASTERPLAN, width, true, true, opt?.data?.checked || false)
        }).then(masterPlanPng => {
            return this._finishGeneratingPDF(regularPng, blankPng, masterPlanPng, winPrint)
        }).catch(err => {
            console.error('error occured', err)
            showErrorBox(this.msgSvc, $localize`Generowanie PDF`, $localize`Wystąpił nieznany błąd`)
        }).finally(() => {
            this.stopInProgress()
        })
    }

    private _finishGeneratingPDF(regularPng: PngImage, blankPng: PngImage, masterPlanPng: PngImage, winPrint: any | undefined) {
        if (!this.canvas) {
            throw new Error('Canvas not initialized')
        }

        let selectedPDFPages = this.pdfOptions.filter(p => p.data?.checked && p.data.page).map(p => p.data?.page)

        const title = $localize`Projekt trasy dla konkursu ${this.cfg.params.title}`
        const pagesMargin = this.cfg.getPageMarginPx()

        // for A4 in pdfmake
        const pageSize = this.cfg.getPageSizePx().subtract(pagesMargin * 2)
        let pageWidth = pageSize.width
        let pageHeight = pageSize.height
        let prevPagePresent = false

        let orientation = 'landscape'
        if (regularPng.width < regularPng.height) {
            orientation = 'portrait'
            const w = pageWidth
            pageWidth = pageHeight
            pageHeight = w
        }
        const doc: any = {
            info: {
                title: title,
                author: this.auth.getUserDisplayName(),
                subject: title,
                keywords: 'course design, parkour, equestrian',
                creator: 'https://parkour.design',
            },
            pageSize: 'A4',
            pageOrientation: orientation,
            pageMargins: pagesMargin,
            content: []
        };

        // ** page with regular design **

        if (selectedPDFPages.includes('main')) {
            let [width, height, marginX, marginY] = this._estimateImagePosInPDF(pageWidth - 10, pageHeight - 10, regularPng)
            doc.content.push({
                image: regularPng.data,
                width: width,
                height: height,
                margin: [marginX, marginY]
            })

            prevPagePresent = true
        }

        // ** page with blank design **

        if (selectedPDFPages.includes('blank')) {
            let [width, height, marginX, marginY] = this._estimateImagePosInPDF(pageWidth - 10, pageHeight - 10, blankPng)
            doc.content.push({
                image: blankPng.data,
                width: width,
                height: height,
                margin: [marginX, marginY]
            })

            prevPagePresent = true
        }

        // ** page with masterplan design **
        if (selectedPDFPages.includes('masterplan')) {
            doc.content.push({
                pageBreak: prevPagePresent ? 'before' : undefined,
                margin: 0,
                text: $localize`Masterplan`,
                fontSize: 20,
                bold: true,
            })

            let [width, height, marginX, marginY] = this._estimateImagePosInPDF(pageWidth, pageHeight - 40, masterPlanPng)
            doc.content.push({
                image: masterPlanPng.data,
                width: width,
                height: height,
                margin: [marginX, marginY]
            })

            prevPagePresent = true
        }

        // ** page with obstacles lists **
        if (!this.cfg.isNoRouteMode() && selectedPDFPages.includes('obstacles')) {
            doc.content.push({
                pageOrientation: 'portrait',
                pageBreak: prevPagePresent ? 'before' : undefined,
                margin: [30, 30, 0, 0],
                text: $localize`Lista przeszkód`,
                fontSize: 20,
                bold: true,
            })

            // obstacles list
            const unit = ' [' + LengthPipe.getUnit(this.cfg.params.distanceUnit) + ']'
            const obstaclesList: any[] = [['', $localize`Typ`, $localize`Nazwa`, $localize`Pozycja` + unit,
                $localize`Odległość od poprzedniej` + unit, $localize`Odległość od startu` + unit, $localize`Materiały`]]
            for (let o of this.getObstaclesTabItems()) {
                obstaclesList.push([
                    {
                        style: {
                            alignment: 'right'
                        },
                        text: (o.obstacle.isObstacle() ? (o.label + '.') : ''),
                    },
                    o.obstacle.getKindName(),
                    o.obstacle.name ? o.obstacle.name : '',
                    DetailComponent._lengthPipe.transform(o.obstacle.getPosition().x - (this.canvas?.field.x || 0), this.cfg.params.distanceUnit, false, undefined, undefined, true) + ', ' +
                    DetailComponent._lengthPipe.transform(o.obstacle.getPosition().y - (this.canvas?.field.y || 0), this.cfg.params.distanceUnit, false, undefined, undefined, true),
                    DetailComponent._lengthPipe.transform(o.distFromPrev, this.cfg.params.distanceUnit, false, undefined, undefined, true),
                    DetailComponent._lengthPipe.transform(o.distFromStart, this.cfg.params.distanceUnit, false, undefined, undefined, true),
                    this._getPdfMaterials(o.obstacle)
                ])
            }

            doc.content.push({
                layout: 'lightHorizontalLines',
                margin: [30, 20, 30, 30],
                table: {
                    headerRows: 1,
                    //widths: [ '*', 'auto', 100, '*' ],
                    body: obstaclesList,
                }
            })

            prevPagePresent = true
        }

        // ** page with objects lists **
        if (selectedPDFPages.includes('objects')) {
            doc.content.push({
                pageOrientation: 'portrait',
                pageBreak: prevPagePresent ? 'before' : undefined,
                margin: [30, 30, 0, 0],
                text: $localize`Lista obiektów`,
                fontSize: 20,
                bold: true,
            })

            const objectsList: any[] = [[]]
            const t = objectsList[0]
            if (this.cfg.isNoRouteMode()) {
                t.push('') // label (jumping order)
            }
            t.push($localize`Typ`)
            t.push($localize`Nazwa`)
            t.push($localize`Pozycja`)
            if (this.cfg.isScoreAllowed()) {
                t.push($localize`Punktacja`)
            }
            t.push($localize`Materiały`)

            const objects = this.canvas.getNonPathObjects().sort((a, b) => {
                const va = (a instanceof Obstacle && a.noRouteLabel) ? a.noRouteLabel : 1000
                const vb = (b instanceof Obstacle && b.noRouteLabel) ? b.noRouteLabel : 1000
                return va - vb
            })
            for (let o of objects) {
                const row = []
                row.push(o.getKindName())
                row.push(o instanceof PathObject && o.name ? o.name : '')
                row.push((((o.getPosition().x - this.canvas.field.x) / 100).toFixed(0) + ', ' +
                    ((o.getPosition().y - this.canvas.field.y) / 100).toFixed(0)))
                if (this.cfg.isScoreAllowed()) {
                    row.push(o instanceof Obstacle && o.score ? o.score.toFixed(0) : '')
                }
                row.push(this._getPdfMaterials(o))
                if (this.cfg.isNoRouteMode()) {
                    if (o instanceof Obstacle && o.noRouteLabel) {
                        row.unshift({
                            style: {
                                alignment: 'right'
                            },
                            text: o.noRouteLabel.toFixed(0) + '.'
                        } as any)
                    } else {
                        row.unshift('')
                    }
                }
                objectsList.push(row)
            }

            doc.content.push({
                layout: 'lightHorizontalLines',
                margin: [30, 20, 30, 30],
                table: {
                    headerRows: 1,
                    //widths: [ '*', 'auto', 100, '*' ],
                    body: objectsList,
                }
            })
        }

        selectedPDFPages = ['main']

        let filename = title.replace(/[^a-zA-Z0-9\ _\-]+/g, '') + '.pdf'

        const pdfDocGenerator = pdfMake.createPdf(doc)
        return new Promise((resolve, reject) => {
            function unhandled(e: any) {
                reject(e)
            }
            setTimeout(() => {
                reject(new Error('timeout'))
                window.removeEventListener('unhandledrejection', unhandled)
            }, 20000)
            window.addEventListener('unhandledrejection', unhandled)
            if (winPrint) {
                pdfDocGenerator.print({}, winPrint)
                resolve(undefined)
            } else {
                pdfDocGenerator.download(filename, () => {
                    resolve(undefined)
                })
            }
        })
    }

    private _getPdfMaterials(o: ParkourObject): string | ContentSvg {
        let materials
        if (o instanceof ObstacleWithBars && o.materials) {
            const v = o.materials.visible
            o.materials.visible = true
            o.materials.generateSvg()
            o.materials.visible = v
            if (o.materials && o.materials.viewBox && o.materials.width) {
                materials = {
                    svg: '<svg viewBox="' + o.materials.viewBox + '" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                        o.materials.svg + '</svg>',
                    width: (o.materials.width || 0) / 10
                } as ContentSvg
            }
        }
        return materials ? materials : ''
    }

    private saveConfigurationAndClose() {
        if (this.cfg.form.valid) {
            this.toggleTapeMeasure(false)
            this.cfg.getValuesFromForm()
            this.canvas?.changeArenaSize(true)
            this.saveData()
            const data = this.undo.peek()
            this._restoreParkour(data)
            this.updatePath()
            this.setupEventDesigns()
        }
    }

    onFieldSizeOk() {
        this.saveConfigurationAndClose()
        this.canvas?.changeArenaSize()
    }

    onDebugOptionsOk() {
        this.saveConfigurationAndClose()
        this.canvas?.changeArenaSize()
    }

    onCompetitionParamsOk() {
        if (this.cfg.form.controls.eventName.touched) {
            this.cfg.params.userIndex = 0
        }
        this.saveConfigurationAndClose()
    }

    onDisplayOptionsOk() {
        this.saveConfigurationAndClose()
    }

    onValidationSelected(problem: Warning) {
        this.canvas?.resetView()
        this.selectionClear()
        const objects = problem.objects
        if (objects) {
            if (objects.length === 1) {
                this.selectFocusItem(objects[0])
            } else {
                objects.forEach(o => {
                    if (this.canvas?.objects.includes(o)) {
                        this.selectItem(o)
                    }
                })
            }
        }
    }

    onValidationOk() {
        const callback = this.validationCallback
        this.validationCallback = undefined
        if (callback) {
            callback()
        }
    }

    onValidationCancel() {
        this.validationCallback = undefined
    }

    openDialog(dialog: ParkourDialogId) {
        this.parkourDialogs[dialog]?.open()
    }

    private justSaveUserProfile(data: UserProfile) {
        this.userService.updateUserProfile(data).subscribe({
            next: (resp: any) => {
                console.info('updated profile', resp)
            },
            error: (err) => {
                console.error('error occured', err)
                showErrorBox(this.msgSvc, $localize`Zapisywanie ustawień użytkownika`, $localize`Wystąpił nieznany błąd`)
            }
        })
    }

    savePdfOptions() {
        const opts: PdfOptions = {}
        this.pdfOptions.forEach(o => this._forPdfOptionNodes(o, (data) => opts[data.id] = data.checked))
        if (Object.keys(opts).length > 0) {
            this.justSaveUserProfile({
                pdfOptions: opts
            })
        }
    }

    saveUserData() {
        const data: UserProfile = {
            shortcuts: this.uiCommands.shortcuts.toJson(),
        }
        this.justSaveUserProfile(data)
    }

    dragObjectStart(event: any, obj: any) {
        this.draggingObject = obj
        const dx = event.offsetX - (event.target.clientWidth / 2)
        const dy = event.offsetY - (event.target.clientHeight / 2)
        this.draggingOffset = [dx, dy]
    }

    allowDrop(event: any) {
        event.preventDefault()
    }

    objectDrop(ev: any) {
        if (!this.canvasElement || !this.canvas?.paper || !this.draggingObject || !this.draggingOffset) {
            return
        }
        const dragOfs = new paper.Point(this.draggingOffset[0], this.draggingOffset[1])
        const pInWindowCoords = (new paper.Point(ev.x, ev.y)).subtract(dragOfs)
        const pCnvsCorner = new paper.Point(this.canvasElement.nativeElement.parentElement!.offsetLeft,
            this.canvasElement.nativeElement.parentElement!.offsetTop)
        const pInCanvasCoords = pInWindowCoords.subtract(pCnvsCorner)
        const pInViewCoords = this.canvas.paper.view.viewToProject(pInCanvasCoords)

        let objKind: ParkourObjectTemplate
        if (this.draggingObject.kind) {
            objKind = this.draggingObject as ParkourObjectKind
        } else {
            objKind = {
                kind: this.draggingObject.source === 'built-in' ? this.draggingObject.id : 'user-image',
                url: this.draggingObject.url,
                name: '',
                objectClass: this.draggingObject.source === 'built-in' ? Landscape : UserImage,
                imageId: this.draggingObject.id
            }
        }
        this.addObject(objKind, pInViewCoords)
    }

    changeObjectKind(o: any, kind: any) {
        const obj = this.canvas?.createObject({
            ...o.toJson(),
            width: undefined,
            height: undefined,
            length: undefined,
            uuid: undefined,
            kind: kind
        })
        if (obj) {
            this.replaceObject(o, obj)
            o.destroy()
            this.updatePath()
            this.saveData()
        }
    }

    executeObjectAction(o: UiContextMenuItem, event: any) {
        if (!this.dropDownClick && o && o.command) {
            o.command(event)
        }
        this.dropDownClick = false
    }

    setSplitMenuObject(o?: PathObject) {
        this.dropDownClick = true
    }

    private startInProgress() {
        this.inProgress = true
    }

    private stopInProgress() {
        this.inProgress = false
    }

    flipVertical() {
        if (!this.canvas) {
            return
        }
        for (let o of this.canvas.objects) {
            const pos = o.getPosition()
            if (this.canvas.field.contains(pos)) {
                const dy = pos.y - this.canvas.field.y
                const ny = this.canvas.field.bottom - dy
                o.rotate(-2 * o.angle + 180)
                o.setPosition(new paper.Point(pos.x, ny))
            }
        }

        for (let n of this.canvas.obstaclePath.route) {
            for (let mp of n.midPoints) {
                const pos = mp.getPosition()
                const dy = pos.y - this.canvas.field.y
                const ny = this.canvas.field.bottom - dy
                mp.setPosition(new paper.Point(pos.x, ny))
            }
        }

        this.updatePath()
        this.canvas.updateAllGroups()
        this.saveData()
    }

    flipHorizontal() {
        if (!this.canvas) {
            return
        }
        for (let o of this.canvas.objects) {
            const pos = o.getPosition()
            if (this.canvas.field.contains(pos)) {
                const dx = pos.x - this.canvas.field.x
                const nx = this.canvas.field.right - dx
                o.rotate(-2 * o.angle)
                o.setPosition(new paper.Point(nx, pos.y))
            }
        }

        for (let n of this.canvas.obstaclePath.route) {
            for (let mp of n.midPoints) {
                const pos = mp.getPosition()
                const dx = pos.x - this.canvas.field.x
                const nx = this.canvas.field.right - dx
                mp.setPosition(new paper.Point(nx, pos.y))
            }
        }

        this.updatePath()
        this.canvas.updateAllGroups()
        this.saveData()
    }

    rotateRight() {
        if (!this.canvas) {
            return
        }
        const p = this.canvas.field.center.x
        const q = this.canvas.field.center.y
        const sp = p - this.canvas.field.x
        const sq = q - this.canvas.field.y

        for (let o of this.canvas.objects) {
            const pos = o.getPosition()
            if (this.canvas.field.contains(pos)) {
                const nx = p - (pos.y - q) * sp / sq
                const ny = q + (pos.x - p) * sq / sp
                o.rotate(90)
                o.setPosition(new paper.Point(nx, ny))
            }
        }

        for (let n of this.canvas.obstaclePath.route) {
            for (let mp of n.midPoints) {
                const pos = mp.getPosition()
                const nx = p - (pos.y - q) * sp / sq
                const ny = q + (pos.x - p) * sq / sp
                mp.setPosition(new paper.Point(nx, ny))
            }
        }

        this.updatePath()
        this.canvas.updateAllGroups()
        this.saveData()
    }

    rotateLeft() {
        if (!this.canvas) {
            return
        }
        const p = this.canvas.field.center.x
        const q = this.canvas.field.center.y
        const sp = p - this.canvas.field.x
        const sq = q - this.canvas.field.y

        for (let o of this.canvas.objects) {
            const pos = o.getPosition()
            if (this.canvas.field.contains(pos)) {
                const nx = p + (pos.y - q) * sp / sq
                const ny = q - (pos.x - p) * sq / sp
                o.rotate(-90)
                o.setPosition(new paper.Point(nx, ny))
            }
        }

        for (let n of this.canvas.obstaclePath.route) {
            for (let mp of n.midPoints) {
                const pos = mp.getPosition()
                const nx = p + (pos.y - q) * sp / sq
                const ny = q - (pos.x - p) * sq / sp
                mp.setPosition(new paper.Point(nx, ny))
            }
        }

        this.updatePath()
        this.canvas.updateAllGroups()
        this.saveData()
    }

    swapFinishStart() {
        if (!this.canvas) {
            return
        }
        this.canvas.obstaclePath.reverseRounds()
        this.saveData()
        const data = this.undo.peek()
        this._restoreParkour(data)
        this.updatePath()
    }

    editShortcuts() {
        this.keyShortcutsDlgVisible = true
    }

    showDropWarning() {
        showInfoBox(this.msgSvc, $localize`Dodawanie przeszkody lub innego obiektu`,
            $localize`Aby dodać obiekt, przeciągnij jego ikonę na pole`)
    }

    validateParkour(showWindow: boolean, callback?: () => void) {
        this.validator.validate()
        if (this.validator.validationWarnings.length === 0) {
            if (this.validationDialog && !this.validationDialog.visibility) {
                if (callback) {
                    callback()
                } else if (showWindow) {
                    showInfoBox(this.msgSvc, $localize`Sprawdzenie projektu`, $localize`Nie znaleziono problemów`)
                }
            }
        } else {
            if (showWindow) {
                if (!this.validationCallback) {
                    this.validationCallback = callback
                }
                this.openDialog(ParkourDialogId.VALIDATION)
            }
        }
    }

    selectDesign() {
        if (this.selectedDesign) {
            this.hideDesignPreview()
            this.router.navigate(['/designer'], { queryParams: { id: this.selectedDesign.localId, author: this.selectedDesign.author, projectsTab: true } })
        }
    }

    onDesignsFilter(event: TableFilterEvent) {
        this.filteredDesigns = event.filteredValue
        this.compSorter.sort()
    }

    onImageError(design: any) {
        design.pngUrl = null
    }

    onImageLoad(ev: any, design: any) {
        const img = ev.target
        const canvas = document.createElement("canvas")
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight
        const ctx = canvas.getContext("2d")
        ctx!.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight)
    }

    showDesignPreview(design: any) {
        if (design.pngUrl) {
            this.designPreviewUrl = design.pngUrl
        } else if (design.png) {
            this.designPreviewUrl = design.png
        } else {
            this.designPreviewUrl = ''
        }
        this.designPreviewVisible = true
    }

    hideDesignPreview() {
        this.designPreviewVisible = false
    }

    editMaterials(o?: ParkourObject) {
        if (!this.features.materialsEditor) {
            showInfoBox(this.msgSvc, $localize`Edytor materiałów`,
                $localize`Twój plan cenowy nie pozwala na edycję materiałów przeszkód.` +
                '\n\n' + $localize`Możesz zmienić plan cenowy w menu Pomoc.`)
            return
        }
        if (!o) {
            const i = this.selection.getFocusItem()
            if (i instanceof Obstacle) {
                o = i
            }
        }
        if (!this.dropDownClick && this.materialsDialog && o instanceof ObstacleWithBars &&
            !this.materialsDialog.visibility) {
            this.materialsDialog.open(o)
            this.updatePath()
        }
        this.dropDownClick = false
    }

    removeMaterials() {
        const i = this.selection.getFocusItem()
        if (i instanceof ObstacleWithBars && i.materials) {
            i.materials.destroy()
            i.materials = undefined
            this.saveData()
        }
    }

    onMaterialsDialogOk() {
        this.saveData()
    }

    onMaterialsDialogDelete() {
        this.saveData()
    }

    cloneDesign(design: DesignSchema) {
        // if design to clone is currently edit design
        // then take it so the latest edits are cloned
        if (design.localId === this.cfg.params.localId) {
            design = this.cfg.params
        }
        const [localId, newDesign, obs] = this.store.cloneDesign(design)

        this.subs.add(obs.subscribe({
            next: (docRef: any) => {
                showSuccessBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Projekt zduplikowano`)
            },
            error: (err: any) => {
                console.error("Error adding design: ", err)
                showErrorBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Wystąpił nieznany błąd`)
            }
        }))

        const idx = this.allMyDesigns.findIndex(d => d.localId === design.localId)
        if (idx >= 0) {
            this.allMyDesigns.splice(idx, 0, newDesign)
            this.allMyDesigns = this.allMyDesigns.slice(0)
            this.setupEventDesigns()
            this.selectedDesign = newDesign
            this.selectDesign()
        }
    }

    deleteDesign(design: DesignSchema) {
        const deletedLocalId = design.localId

        // the list of events will be updated this way if selected design is deleted
        // if there is next on the displayed list, it will be selected
        // else if there is previous, it will be selected
        // if displayed list if empty (no designs with filtered event), all designs will be displayed and first selected
        // if no designs at all, exit to projects list
        let table: DesignSchema[] | undefined
        if (this.filteredDesigns && this.designsTable?.hasFilter()) {
            table = this.filteredDesigns
        } else {
            table = this.allMyDesigns
        }
        const idx = table.findIndex(d => d.localId === deletedLocalId)
        let nextToSelect: DesignSchema | undefined
        if (idx >= 0 && idx < table.length - 1) {
            nextToSelect = table[idx + 1]
        } else if (idx > 0) {
            nextToSelect = table[idx - 1]
        }
        const newList = []
        for (let i = 0; i < this.allMyDesigns.length; i++) {
            if (this.allMyDesigns[i].localId !== design.localId) {
                newList.push(this.allMyDesigns[i])
            }
        }
        this.allMyDesigns = newList
        this.setupEventDesigns()

        if (!this.selectedDesign || this.selectedDesign.localId === deletedLocalId) {
            let select: DesignSchema | undefined
            if (nextToSelect) {
                const table = this.allMyDesigns
                select = table.find(d => nextToSelect && d.localId === nextToSelect.localId)
            }
            if (!select && this.allMyDesigns.length > 0) {
                select = this.allMyDesigns[0]
            }
            this.selectedDesign = select
            this.selectDesign()
        }

        this.subs.add(
            this.store.deleteDesign(design).subscribe({
                next: (ok) => {
                    if (this.allMyDesigns.length === 0) {
                        this.exitToListOfDesigns()
                    }
                },
                error: (error) => {
                    console.error("Error deleting design: ", error)
                    showErrorBox(this.msgSvc, $localize`Usuwanie projektu`, $localize`Wystąpił nieznany błąd`)
                    this.loadLocalDesigns(() => {
                        if (this.allMyDesigns.length === 0) {
                            this.exitToListOfDesigns()
                        } else {
                            this.selectedDesign = design
                        }
                    })
                },
            })
        )
    }

    exitToListOfDesigns() {
        this.router.navigate(['/'])
    }

    createNewDesign() {
        this.router.navigate(['/'], { queryParams: { newDesign: true } })
    }

    zoom100Percent() {
        this.canvas?.setZoom(100)
    }

    doZoomDelta(d: number) {
        this.canvas?.setZoom(this.canvas.zoom + d)
    }

    groupObjects(items: Selectable[]) {
        this._groupObjects(items.filter(o => o instanceof ParkourObject))
    }

    private _groupObjects(items: Selectable[]) {
        if (!this.canvas || items.length < 2) {
            return
        }
        const canvas = this.canvas
        items.forEach(item => {
            const p = item.parentSelector
            if (p && p instanceof ParkourObjectGroup) {
                this.deselectItem(p)
                if (p.remove(item) <= 1) {
                    canvas.deleteObject(p)
                } else {
                    this.selectItem(p)
                }
            }
        })
        const g = new ParkourObjectGroup(items, this.cfg, this.canvas, this.canvas.objectGroups.length)
        this.canvas.objectGroups.push(g)
        g.children.forEach(c => this.deselectItem(c))
        this.selectItem(g)
        this.saveData()
    }

    ungroupObjects(items: Selectable[]) {
        const groups = items.filter(i => i instanceof ParkourObjectGroup)
        items.forEach(i => {
            const parent = i.parentSelector
            if (parent instanceof ParkourObjectGroup) {
                groups.push(parent)
            }
        })
        this.ungroup(groups)
        this.saveData()
    }

    private ungroup(items: Selectable[]) {
        if (!this.canvas) {
            return
        }
        const canvas = this.canvas
        items.forEach(group => {
            if (group instanceof ParkourObjectGroup) {
                group.children.forEach(c => this.selectItem(c))
                this.deselectItem(group)
                canvas.deleteObject(group)
            }
        })
    }

    openImageSelectTab(forceImagesTab: boolean) {
        if (this.activeTab.id === TabIds.IMAGES && !forceImagesTab) {
            this._switchTabMenu(TabIds.OBJECTS)
        } else {
            this._switchTabMenu(TabIds.IMAGES)
        }
    }

    imageSelectFinish(event: any) {
        const imageSelected = event
        const oldObj = this.selection.getFocusItem()
        if (!this.canvas || !oldObj || !(oldObj instanceof UserImage)) {
            return
        }

        // TODO: check oldObj type: user-image or built-in-image

        const newObjData = {
            ...oldObj.toJson(),
            width: undefined,
            height: undefined,
            imageSize: undefined,
            length: undefined,
            uuid: undefined,
            imageId: undefined,
        }

        if (imageSelected.source === 'built-in') {
            newObjData.kind = imageSelected.id
        } else if (imageSelected.source !== 'built-in') {
            newObjData.kind = 'user-image'
            newObjData.imageId = imageSelected.id
        }

        const newObj = this.canvas.createObject(newObjData)
        this.replaceObject(oldObj, newObj)
        oldObj.destroy()

        return
    }

    enableSelect() {
        window.removeEventListener('selectstart', this._preventDefault)
    }

    disableSelect() {
        window.addEventListener('selectstart', this._preventDefault)
    }

    private _preventDefault(e: Event) {
        e.preventDefault()
    }

    togglePathEditMode(force?: boolean) {
        const toggler = this.uiCommands.getToggler(UiCommandId.CHANGE_PATH_EDIT_MODE)
        if (!toggler) {
            return
        }
        if (force === undefined) {
            toggler.toggle()
        } else {
            toggler.onOffState = force
        }
        if (toggler.onOffState) {
            this.cfg.params.editMode = EditMode.DRAWING
        } else {
            this.cfg.params.editMode = EditMode.CONNECTOR
        }
        this.saveData()
    }

    toggleTapeMeasure(force?: boolean) {
        const toggler = this.uiCommands.getToggler(UiCommandId.TAPE_MEASURE)
        if (!toggler) {
            return
        }
        if (force !== undefined) {
            toggler.onOffState = force
        } else {
            toggler.toggle()
        }
        if (toggler.onOffState) {
            this.cursor = Cursor.TAPE_MEASURE
            this.selectionClear()
        } else {
            this.cursor = Cursor.DEFAULT
            this.tapeMeasure.end()
        }
    }
}
