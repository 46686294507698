import { Injectable } from '@angular/core'

import { getApp } from '@angular/fire/app'
import { User } from '@angular/fire/auth'
import { Firestore, addDoc, collection, getDocs, onSnapshot, query, where } from '@angular/fire/firestore'
import { getFunctions, httpsCallable } from '@firebase/functions'
import { getStripePayments } from "@invertase/firestore-stripe-payments"
import { BehaviorSubject, Subject, combineLatest, defer, distinctUntilChanged, filter, from, Observable, throwError } from 'rxjs'

import { SubscriptionInterval, SubscriptionPlan } from './subscription-plans'
import { SubscriptionLevel } from '../design.schema'

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    user?: User

    payments: any = null
    private _products: any = { advanced: null, masters: null }
    private _productsSub = new BehaviorSubject<any>(this._products)

    private _subscriptionPlan = new BehaviorSubject<SubscriptionPlan | undefined>(undefined)

    constructor(
        private firestore: Firestore,
    ) {
        this._getProducts()
    }

    getSubscriptionPlan(): Observable<SubscriptionPlan> {
        return this._subscriptionPlan.pipe(
            filter((plan: any) => plan !== undefined),
            distinctUntilChanged((prev, curr) => (prev === curr || (prev?.name === curr?.name && prev?.interval === curr?.interval)))
        )
    }

    private _getUserSubsriptionPlan() {
        if (!this.user) {
            return
        }
        // create a query object to the current users active subscriptions
        const q = query(
            collection(this.firestore, 'stripe-customers', this.user.uid, 'subscriptions'),
            where('status', 'in', ['trialing', 'active'])
        );

        // fetch the active subscriptions
        const obsDocs = defer(() => from(getDocs(q)))

        const obs = combineLatest(obsDocs, this._productsSub)
        obs.subscribe({
            next: ([subsResp, prods]: any[]) => {
                if (!prods || !prods.masters || !prods.advanced) {
                    return
                }
                if (subsResp.empty) {
                    // user doesn't have any active subscriptions
                    this._subscriptionPlan.next({ name: SubscriptionLevel.STARTER })
                    return
                }

                // assuming user only has one active subscription max
                const sub = subsResp.docs[0].data()
                if (sub.quantity !== 1) {
                    console.error('bad subscription', sub)
                    return
                }
                const plan: SubscriptionPlan = { name: SubscriptionLevel.STARTER }
                for (const [name, pp] of Object.entries(prods)) {
                    if (!pp) {
                        continue
                    }
                    const p = pp as any
                    if (p.monthly.id === sub.items[0].plan.id) {
                        plan.name = name as SubscriptionLevel
                        plan.interval = SubscriptionInterval.MONTHLY
                    } else if (p.yearly.id === sub.items[0].plan.id) {
                        plan.name = name as SubscriptionLevel
                        plan.interval = SubscriptionInterval.YEARLY
                    }

                }
                if (plan.name) {
                    this._subscriptionPlan.next(plan)
                }
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
    }

    private async _getProducts() {
        const app = getApp()
        this.payments = getStripePayments(app, {
            productsCollection: "stripe-products",
            customersCollection: "stripe-customers",
        });

        const q = query(
            collection(this.firestore, 'stripe-products'),
            where('active', '==', true)
        );
        const querySnapshot = await getDocs(q);
        const productsPromises = querySnapshot.docs.map(async (productDoc) => {
            let productInfo = productDoc.data();

            // fetch prices subcollection per product
            const pricesCollection = collection(productDoc.ref, 'prices');
            const qp = query(pricesCollection, where('active', '==', true))
            const priceQuerySnapshot = await getDocs(qp)

            // process prices
            for (const priceDoc of priceQuerySnapshot.docs) {
                const price = priceDoc.data()
                // console.info('price', price)
                if (price.interval === 'year') {
                    productInfo['yearly'] = price
                    productInfo['yearly']['id'] = priceDoc.id
                    productInfo['yearlyPrice'] = price.unit_amount / 100
                } else {
                    productInfo['monthly'] = price
                    productInfo['monthly']['id'] = priceDoc.id
                    productInfo['monthlyPrice'] = price.unit_amount / 100
                }
            }
            return productInfo;
        });

        const products = await Promise.all(productsPromises);
        for (const p of products) {
            if (p.name === 'Advanced' || p.name === 'Developmental' || p.metadata?.level === '1') {
                this._products.advanced = p
            } else if (p.name === 'Masters' || p.metadata?.level === '2') {
                this._products.masters = p
            } else {
                console.info('unrecognized stripe prod', p)
            }
        }
        this._productsSub.next(this._products)
    }

    init(user: User) {
        this.user = user
        this._getUserSubsriptionPlan()
    }

    finit() {
        if (!this.user) {
            return
        }
        this.user = undefined
        this._subscriptionPlan.next({ name: SubscriptionLevel.STARTER })
    }

    subscribeToPlan(plan: string, interval: string): Observable<any> {
        if (!this.user) {
            return throwError(() => new Error('User not found'))
        }
        // console.info('this._products', plan, interval, this._products)
        const priceId = this._products[plan][interval].id
        if (!priceId) {
            console.error('stripe product not found', plan, interval)
            return throwError(() => new Error('Stripe product not found'))
        }

        let checkoutSessionData = {
            price: priceId,
            success_url: window.location.origin + '/',
            cancel_url: window.location.origin + '/user-plan',
            automatic_tax: {
                enabled: true
            },
            allow_promotion_codes: true,
            tax_id_collection: {
                enabled: true,
                required: 'if_supported',
            }
        }

        const obs = new Subject<any>()
        addDoc(
            collection(this.firestore, `stripe-customers/${this.user.uid}/checkout_sessions`),
            checkoutSessionData
        ).then((checkoutSessionRef) => {
            // The Stripe extension creates a payment link for us
            onSnapshot(checkoutSessionRef, (snap) => {
                // this is invoked a few times by onSnapshot, every time it the record changes on server-side
                // until cs.url is present
                const cs = snap.data();
                if (cs?.error) {
                    // handle error
                    console.error('error occured', cs.error)
                    return
                }
                if (cs?.url) {
                    obs.next('ok')
                    window.location.assign(cs.url);  // redirect to payment link
                }
            });
        }).catch((error) => {
            console.error('problem creating checkout session', error)
            obs.error(error)
        })

        return obs
    }

    async teleportToCustomerPortal() {
        const app = getApp()
        const instance = getFunctions(app, 'europe-west1');
        const function_ref = httpsCallable(instance, 'ext-firestore-stripe-payments-createPortalLink');

        await function_ref({
            returnUrl: window.location.origin + '/user-plan'
        }).then(({ data }: any) => {
            window.open(data.url, '_blank')?.focus()
        }).catch((e) => console.error('error', e.message))
    }
}
